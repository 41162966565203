import {http} from "http/index";
import qs from "utils/query";

import {formatDetails, formatRenter, formatJointScreeningRenters} from "./formatters";

export const getList = async ({branchId}) =>
  http.get(`/agent/joint-screening${qs.stringify({branchId}, {skipNulls: true})}`);

export const getDetails = async ({id, branchId}) =>
  http
    .get(`/agent/joint-screening/${id}${qs.stringify({branchId}, {skipNulls: true})}`)
    .then(formatDetails);

export const getDetailsBranchConnections = async ({id}) =>
  http.get(`/agent/joint-screening/branch-connection/${id}`).then(formatDetails);

export const create = async data => http.post("/agent/joint-screening", data);

export const remove = async ({id}) => http.delete(`/agent/joint-screening/${id}`);

export const update = async ({id, values}) => http.put(`/agent/joint-screening/${id}`, values);

export const removeRenter = async ({renterId, screeningID}) =>
  http.delete(`/agent/joint-screening/${screeningID}/renters/${renterId}`);

export const updateRenter = async ({renterId, screeningID, data}) =>
  http.put(`/agent/joint-screening/${screeningID}/renters/${renterId}`, data);

export const addRenter = async ({screeningID, data}) =>
  http.post(`/agent/joint-screening/${screeningID}/renters`, data).then(formatRenter);

export const getRenters = async ({branchId, search, screeningType}) =>
  http
    .get(
      `/agent/branch-connections/renters/suggestion/search${qs.stringify({
        branchId,
        search,
        screeningType,
      })}`,
    )
    .then(formatJointScreeningRenters);

export const downloadPDF = async ({id, pdfType, branchId}) =>
  http.get(`/pdf/joint-screening/${id}${qs.stringify({branchId, type: pdfType})}`);

export const getUsersConnectionStatuses = async ({branchId, usersIds}) =>
  http.get(
    `/agent/branch/${branchId}${qs.stringify(
      {renters: usersIds},
      {encode: false, arrayFormat: "comma"},
    )}`,
  );

export const resendInvite = async data => http.post("/agent/invites/resend-rejected", data);

/**
 * Requests the list of Joint Screenings, associated with the Renter.
 */
export const getRenterJointScreenings = async (renterId, branchId) =>
  http.get(`/agent/households/renter/${renterId}/households?branchId=${branchId}`);
