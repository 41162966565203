import {http} from "http/index";
import qs from "utils/query";

import {
  formatConnectedList,
  formatDetails,
  formatInvitedList,
  formatFavoritesList,
} from "./formatters";

export const getAcceptedList = async ({branchId}) =>
  http
    .get(`/agent/branch-connections/rent-passports${qs.stringify({branchId})}`)
    .then(formatConnectedList(branchId));

export const getInvitedList = async ({branchId}) =>
  http
    .get(`/agent/branch-connections/invites${qs.stringify({branchId})}`)
    .then(formatInvitedList(branchId));

export const getFavouritesList = async ({branchId}) =>
  http
    .get(`/agent/branch-connections/rent-passports${qs.stringify({branchId})}`)
    .then(formatFavoritesList(branchId));

/**
 * Requests the information about the prices of available screenings
 * @param {Object} getScreeningPricesParams
 * @param {string} [getScreeningPricesParams.branchConnectionId] - The branchConnectionId, optional if screeningType is provided.
 * @param {"RENTER"|"GUARANTOR"|"INDIRECT_GUARANTOR"} [getScreeningPricesParams.screeningType] - The screeningType, optional if branchConnectionId is provided.
 */
export const getScreeningPrices = async ({branchConnectionId, screeningType}) =>
  http.get(
    `/agent/branch-connections/screening-prices${qs.stringify(
      {branchConnectionId, screeningType},
      {skipNulls: true},
    )}`,
  );

/**
 * Requests the information about the prices of available addons per screenings
 * @param {Object} getAddonsPrices
@param {string} [getScreeningPricesParams.branchConnectionId] - The branchConnectionId, optional if screeningType is provided.
 * @param {"RENTER"|"GUARANTOR"|"INDIRECT_GUARANTOR"} [getScreeningPricesParams.screeningType] - The screeningType, optional if branchConnectionId is provided.
 */
export const getAddonsPrices = async ({branchConnectionId, screeningType}) =>
  http.get(
    `/agent/branch-connections/addons-prices${qs.stringify(
      {branchConnectionId, screeningType},
      {skipNulls: true},
    )}`,
  );

/**
 * Requests check if agent already has connection with specific Email
 * @param {Object} getCheckAgentConnectionWithEmailProps
 * @param {string} getCheckAgentConnectionWithEmailProps.branchId
 * @param {"RENTER"|"GUARANTOR"} getCheckAgentConnectionWithEmailProps.email
 */
export const getCheckAgentConnectionWithEmail = async ({branchId, email}) =>
  http.get(
    `/agent/branch-connections/access/email${qs.stringify({branchId, email}, {skipNulls: true})}`,
  );

/**
 * Request get BrachConnectionId by renterId and branchId for old RPDetails page
 * @param {Object} getCheckAgentConnectionWithEmailProps
 * @param {string} getCheckAgentConnectionWithEmailProps.branchId
 * @param {"RENTER"|"GUARANTOR"} getCheckAgentConnectionWithEmailProps.email
 */
export const getBrachConnection = async ({renterId, guarantor, branchId}) =>
  http.get(
    `/agent/branch-connections/rent-passport/${renterId}${qs.stringify(
      {guarantor, branchId},
      {skipNulls: true},
    )}`,
  );

export const getIsFavorite = async ({branchId, renterId, guarantor}) =>
  http.get(`/agent/rent-passport/is-favourite/${renterId}${qs.stringify({branchId, guarantor})}`);

/**
 * @typedef {Object} rpOverviewResponse
 * @property {Object} moveInDate
 * @property {string} moveInDate.moveFrom
 * @property {string} moveInDate.moveTo
 * @property {boolean} guarantorRequested
 * @property {boolean} identityVerificationRequested
 * @property {boolean} rightToRentRequested
 * @property {"SCREENING" | "IN_TENANCY" | "ARCHIVED"} tenancyStatus
 * @property {string} moveInDate.renterId
 * @property {string} moveInDate.branchId
 */

/**
 * Details of RP on branch screening details. Use just that values what discribed in type rpOverviewResponse
 * @param {Object} getRPOverviewParams
 * @param {string} getRPOverviewParams.branchId
 * @param {string} getRPOverviewParams.renterId
 * @param {boolean} getRPOverviewParams.guarantor
 * @returns {rpOverviewResponse}
 */

export const getRPOverview = async ({branchConnectionId}) =>
  http.get(`/agent/branch-connections/details/${branchConnectionId}`);

export const getBranchScreeningDetails = ({branchConnectionId}) =>
  http.get(`/agent/reference/branch-connection/${branchConnectionId}`).then(formatDetails);

export const getIdVerificationSection = async ({branchId, passportDataId, renterId}) =>
  http.get(
    `/agent/identity-verification/passport-data/${passportDataId}${qs.stringify({
      branchId,
      renterId,
    })}`,
  );

export const getCreditCheckSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/credit-check/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const getSavingsSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/savings/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const getIncomeSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/income/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const getEmployerReferenceSection = async ({passportDataId, referenceId}) =>
  http.get(
    `/agent/employer-reference/passport-data/${passportDataId}${qs.stringify({referenceId})}`,
  );

export const getLandlordReferenceSection = async ({passportDataId, referenceId}) =>
  http.get(
    `/agent/landlord-reference/passport-data/${passportDataId}${qs.stringify({referenceId})}`,
  );

export const getRightToRentSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/right-to-rent/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const getRentSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/rent/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const getGuarantorsSection = async ({passportDataId, referenceId}) =>
  http.get(`/agent/guarantors/passport-data/${passportDataId}${qs.stringify({referenceId})}`);

export const overrideRPStatus = async ({renterId: userId, note, status, branchId}) =>
  http.post(`/agent/reference/override`, {userId, note, status, branchId});

export const downloadPdfReport = async ({renterId, type, branchId}) =>
  http.get(`/pdf/rent-passport/referencing/${renterId}${qs.stringify({branchId, type})}`);

/**
 * Upgrades screening type to higher tier. `accessLevel` is one of screening types (INSTANT, FULL, etc.)
 */
export const upgradeScreeningType = async ({accessLevel, branchId, renterId}) =>
  http.post("/full-reference-request/upgrade-screening-type", {accessLevel, branchId, renterId});

export const getIsGuarantor = async (renterId, branchConnectionId) =>
  http.get(
    `/agent/branch-connections/is-guarantor${qs.stringify(
      {userId: renterId, branchConnectionId},
      {skipNulls: true},
    )}`,
  );

export const cancelGuarantorRequest = async ({branchConnectionId}) =>
  http.delete(`/agent/guarantor-request/branch-connection/${branchConnectionId}`);

export const getUserBranchConnectionData = async ({userId, branchId, screeningType}) =>
  http.get(
    `/agent/branch-connections/branch/${branchId}/user/${userId}/details?screeningType=${screeningType}`,
  );

/**
 * Requests the list of Branch Connection Renters (Renters on UI) by Renter information.
 * It is used in suggestion search to attach Branch Connection Renters to another entity.
 */
export const searchBranchConnectionRenters = async ({branchId, search, screeningType}) =>
  http.get(
    `/agent/branch-connections/renters/suggestion/search${qs.stringify({
      branchId,
      search,
      screeningType,
    })}`,
  );
