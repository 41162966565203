export default {
  banners: {
    rentAndLegalProtection: {
      partnerServices: "Partner Services",
      rentGuaranteeAndLegalExpenses: "Rent Guarantee and Legal Expenses",
      descriptions: {
        JS: "Need a rent and legal protection for this household?",
        RENTER: "Need a rent and legal protection for this renter?",
        TENANCY: "Need a rent and legal protection for this tenancy?",
      },
      subjectTo: "*Subject to meeting eligibility criteria",
      buyNow: "Buy now",
      signUp: "Sign up",
    },
  },
  CTA: {
    accept: "Accept",
    acceptCancelRequest: "Yes, cancel the request",
    addBranch: "Add Branch",
    affoTy: "Affordability",
    allBranches: "All Branches",
    areUSure: "Are you sure?",
    backToResults: "Back to Results",
    begin: "Begin",
    branchSelector: "Branch Selector",
    cancel: "Cancel",
    cancelInvite: "Cancel Invite",
    cancelRequest: "Cancel Request",
    clear: "Clear",
    clearAll: "Clear All",
    close: "Close",
    checkHere: "Check Here",
    complete: "Complete",
    confirm: "Confirm",
    confirmCancellation: "Confirm Cancellation",
    confirmInviteCancel: "Confirm invite cancellation",
    contactSupport: "Contanct Support",
    continue: "Continue",
    copiedToClipboard: "Copied to Clipboard!",
    failedToCopyToClipboard: "Failed to copy to Clipboard!",
    copy: "Copy",
    copied: "Copied",
    createDeposit: "Create Deposit",
    createJointScreening: "Create Household Screening",
    createTenancy: "Create Tenancy",
    decline: "Decline",
    delete: "Delete",
    document: "Document",
    documents: "Documents",
    done: "Done",
    doNotAccept: "Do not accept",
    downloadAll: "Download all",
    downloadReport: "Download Report",
    downloadReportFull: "Download Full Report PDF",
    downloadReportFullServer: "Download Full Report PDF",
    downloadReportShort: "Download Summary PDF",
    downloadReportShortServer: "Download Summary PDF",
    downloadScreening: "Download Screening",
    free: "£ FREE",
    edit: "Edit",
    editUser: "Edit User",
    endDate: "End Date",
    goBack: "Go back",
    hideAll: "Hide All",
    inPartnershipWith: "In partnership with",
    inviteTeamMember: "Invite Team Member",
    inviteUser: "Invite User",
    learnMore: "Learn more",
    max: "Max",
    min: "Min",
    month: "Month",
    months: "Months",
    next: "Next",
    notNow: "Not now",
    pa: "p/a",
    pcm: "pcm",
    pcw: "pcw",
    readyToComplete: "Ready To Complete",
    reinvite: "Re-invite",
    reject: "Reject",
    rejected: "Rejected",
    rejectedBy: "Rejected by",
    remove: "Remove",
    rentShare: "Rent Share",
    request: "Request",
    requestAnotherRP: "Request Another Rent Passport",
    requestGuarantor: "Request Guarantor",
    requestRP: "Request Rent Passport",
    requestScreening: "Request Comprehensive Screening",
    resendInvite: "Resend Invite",
    reset: "Reset",
    save: "Save",
    saveChanges: "Save Changes",
    selectAll: "Select All",
    send: "Send",
    sendReminder: "Send reminder",
    sendRequest: "Send Request",
    setActive: "Set Active",
    setInactive: "Set Inactive",
    showAll: "Show All",
    startDate: "Start Date",
    submit: "Submit",
    submitRightToRent: "Submit Right to Rent",
    submitted: "Submitted",
    status: "Status",
    total: "Total",
    transaction: "Transaction",
    transactions: "Transactions",
    typeHere: "Type here",
    upgrade: "Upgrade",
    guarantor: "Guarantor",
    renter: "Renter",
    vat: "VAT",
    verifiedBy: "Verified by",
    viewDetails: "View Details",
    viewRP: "View Rent Passport",
    yesCont: "Yes, continue",
    yesImSure: "Yes, I’m sure",
    yesReinvite: "Yes, re-invite",
  },
  searchInput: {
    listTitle: {
      property: "Properties Found",
      address: "Address Lookup",
    },
    noData: {
      title: "No Results",
      ADDRESS:
        "Type you full UK Postcode. If you are having trouble locating you address try adding a space",
      RENTER:
        "Type renter name or email. If you are having trouble locating renter try enter full name",
      TENANCY:
        "No tenancies found. If you are having trouble locating tenancy try enter full name, renter data or internalCode",
      PROPERTY:
        "No properties found. If you are having trouble locating property try enter full name, renter data or internalCode",
    },
    footer: {
      address: {
        text: "Can't find the address?",
        btnText: "Enter address manually",
      },
    },
  },
  options: {
    isPropertyDamaged: {
      YES: "Yes",
      NO: "No",
      NOT_INSPECTED: "Not inspected",
    },
    contractLength: {
      UNDER_1_MONTH: "Under 1 Month",
      MONTHS_2: "2 Months",
      MONTHS_3: "3 Months",
      MONTHS_4: "4 Months",
      MONTHS_5: "5 Months",
      MONTHS_6: "6 Months",
      MONTHS_7: "7 Months",
      MONTHS_8: "8 Months",
      MONTHS_9: "9 Months",
      MONTHS_10: "10 Months",
      MONTHS_11: "11 Months",
      MONTHS_12: "12 Months",
      OVER_12_MONTHS: "Over 12 Months",
    },
  },
  date: {
    days: "Days",
    day: "Day",
    expiring: "Expiring",
    expiringIn: "Expiring in",
    month: "Month",
    months: "Months",
    today: "Today",
    week: "Week",
    weeks: "Weeks",
  },
  filter: {
    labels: {
      affordability: "Affordability",
      branch: "Branch",
      agentStatus: "Agent Status",
      authDecision: "Auth Decision",
      completion: "Completion",
      daysFromNow: "Invited in the",
      documentVerificationStatus: "Document status",
      dateCreated: "Date Created",
      endOfTenancy: "End of Tenancy Date",
      expireDate: "Date Expires",
      flagged: "Flags",
      fraudStatus: "Fraud Status",
      incomeSource: "Income Source",
      overallStatus: "Overall Status",
      requestStatus: "Request Status",
      reSubmitted: "Re-submitted Date",
      savingType: "Savings Type",
      screening: "Screening",
      screeningType: "Screening",
      status: "Status",
      statusIDNow: "IDnow Status",
      submitted: "Submitted Date",
      subscription: "Subscription",
      type: "Type",
      updatedAt: "Date/Time",
    },
  },
  notesTypes: {
    INTERNAL: "Internal Note",
    AGENT: "Agent Note",
    REPORT: "Report Note",
  },
  statuses: {
    accept: "Accept",
    active: "Active",
    autoVerified: "Auto verified",
    verified: "Verified",
    cancelled: "Cancelled",
    connected: "Connected",
    complete: "Complete",
    completed: "Completed",
    contract: "Contract",
    expired: "Expired",
    pendingVerification: "Pending verification",
    inProgress: "In Progress",
    inviteSent: "Invite Sent",
    inactive: "Inactive",
    pending: "Pending",
    submitted: "Submitted",
    accepted: "Accepted",
    rejected: "Rejected",
    failed: "Failed",
    feedbackRequested: "Feedback Requested",
    fair: "Fair",
    pass: "Pass",
    paid: "Paid",
    potentialIssue: "Potential issue",
    provideInPerson: "Provide in Person",
    declined: "Declined",
    consider: "Consider",
    notStarted: "Not started",
    requested: "Requested",
    incomplete: "Incomplete",
    required: "Required",
    notRequired: "Not required",
    notRequested: "Not requested",
    notRented: "Not rented",
    notRentedUK: "Not rented in the UK",
    notFound: "Not found",
    notVerified: "Not verified",
    couldNotFind: "Could not find",
    notEmployed: "Not employed",
    notLivedUK: "Not lived in UK",
    manualVerified: "Manual verified",
    negativeFeedback: "Negative feedback",
    veryPoor: "Very Poor",
    excellent: "Excellent",
    unableToVerify: "Unable to verify",
    unableToProvide: "Unable to provide",
    warnings: "Warnings",
    manualVerification: "48hrs Verification",
    warning: "Warning",
    poor: "Poor",
    readyForReview: "Ready for review",
    good: "Good",
    yes: "Yes",
    draft: "Draft",
    no: "No",
    new: "New",
    refunded: "Refunded",
    requestDeclined: "Request Declined",
    none: "None",
    iva: "IVA",
    bankruptcy: "Bankruptcy",
    both: "Bankruptcy and IVA",
    issues: "Issues",
    updated: "Updated",
    homeowner: "Homeowner",
    homeownerRow: "Home Owner",
    notApplicable: "Not applicable",
    notHomeowner: "Not a homeowner",
    guarantorIsNotHomeowner: "Guarantor is not a homeowner",
    notUKResident: "Not UK Resident",
    highRisk: "High Risk",
    rentReady: "Rent Ready",
    noIncome: "No Income",
    unableToValidate: "Unable to Validate",
    potentialFraud: "Potential Fraud",
    selfCertified: "Self Certified",
  },
  statusBottomNotes: {
    readyToComplete: "Ready to complete",
    emailNotVerified: "Email not verified",
    emailVerified: "Email Verified",
    withConsideration: "with consideration",
  },
  employmentBasis: {
    ZERO_HOUR_CONTRACT: "Zero Hour Contract",
    PERMANENT: "Permanent",
    CONTRACT: "Temporary",
  },
  documentTypes: {
    PASSPORT: "Passport",
    DRIVERS_LICENSE: "Driver license",
    IDCARD: "ID card",
    RESIDENCE_PERMIT: "Residence permit",
  },
  documentGroups: {
    RTR_TIME_LIMITED: "Proof of time-limited right to rent (List B)",
    RTR_NO_TIME_LIMITED: "Proof of unlimited right to rent (List A)",
  },
  fileUploader: {
    buttonText: "Upload documents",
    uploading: "Uploading",
    uploaded: "Uploaded",
    actionSheetBtns: ["Take a photo", "Photo Library", "Choose File", "Cancel"],
    uploadedByYou: "Uploaded by you",
    errors: {
      fileTooBig: "This file is too big:",
      filesTooBig: "This files are too big:",
      fileDuplicate: "That file(s) was duplicated - ",
      aLotFilesStart: "It is forbidden to add more than ",
      aLotFilesEnd: " files.",
    },
  },
  creditCheckStatus: {
    VERY_POOR: "Very Poor",
    POOR: "Poor",
    FAIR: "Fair",
    GOOD: "Good",
    EXCELLENT: "Excellent",
    UNKNOWN: "Could not find",
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "Incomplete",
    NOT_LIVED_IN_UK: "Not Lived In UK",
  },
  creditCheck: {
    activeCCJs: "Active CCJs",
    satisfiedCCJs: "Satisfied CCJs",
    insolvency: "Insolvency",
    experianScore: "Experian Score",
  },
  errors: {
    accessDenied: "Access Denied",
    accessToBranchDenied: "Agent doesn't have access to this branch",
    required: "Required",
    invalid: "Invalid",
    errorBackend: "Error loading data. Please try later",
    errorFrontend: "Something went wrong.",
    errorEmailExist: "User already exists with this email",
    errorEmailNotExist: "User with this email does not exist",
    errorVerifyUser: "User is not verified. Please contact support.",
    errorEmail: "Email is not correct",
    errorEmailNotAvailable: "Email is not available",
    errorTenancyInList: "This tenancy already added",
    errorRenterInList: "This renter already added",
    errorPropertyInList: "This property is already added",
    errorPhone:
      "The number you have entered is invalid. Try including the country code (E.g. +39).",
    errorPhoneValidation: "Number validation failed. Try including the country code (E.g. +39)",
    errorPassword:
      "Your password must be at least 8 characters long and contain 1 uppercase, 1 lowercase, 1 number and 1 special character",
    invalidPasswordLength: "Your password must be at least 8 characters long",
    invalidPasswordLowercaseCharacter:
      "Password must contain at least 1 lowercase alphabetical character",
    invalidPasswordUppercaseCharacter:
      "Password must contain at least 1 uppercase alphabetical character",
    invalidPasswordNumericCharacter: "Password must contain at least 1 numeric character",
    invalidPasswordSpecialCharacter: "Password must contain a special character",
    errorFirstName: "First name is not correct",
    errorLastName: "Last name is not correct",
    errorCompanyName: "Company name is not correct",
    errorCompanyRegNumber: "Company house registration number is not correct",
    errorCompanyRegNumberUnAvailable: "Company house registration number is not available",
    errorName: "Name is not correct",
    errorSignIn:
      "The username or password are incorrect. Please check the details provided are correct and try again.",
    errorMinMax: "Can't be greater than max value",
    invalidEmail: "Email is not correct",
    noSelectedBranches: "Please select branches",
    errorPostCode: "Postcode is not correct. Postcode example: EN5 4EW",
    errorNumber: "Please enter a valid number",
    enterHouseNameOrHouseNumber: "You need to enter house name or house number",
    errorRenterConnected: "Renter already connected.",
    errorSameDate: "The new date must be different from the previous one",
    somethingWentWrong: "Something went wrong!",
    networkError: "Network error.",
    alreadyConnectedGuarantor: "You have already connected this user as a Guarantor",
    alreadyConnectedRenter: "You have already connected this user as a Renter",
    loginFailedUnexpectedError:
      "We cannot log you in right now. Please try again in a moment, or contact support for help.",
    invalidCredentials: "Invalid credentials.",
    accountLocked: "The number of attempts is exceeded, your account is locked till",
    notLoad: {
      title: "Sorry, that info didn’t load",
      subTitle: "Please check your internet and try reloading the page.",
      btnText: "Reload page",
      secondaryBtnText: "Contact Help",
    },
    partnerRedirectGenericError: "The redirect link is invalid. Please, contact the support.",
  },
  helpDialog: {
    title: "Need Help?",
    answer: "For answers to common questions",
    faq: "Frequently asked questions",
    linkFAQ: "Visit our FAQs",
    subtitle: "Contact Support",
    description:
      "Our support team is always happy to help. If you have any problems, please don't hesitate to get in touch.",
    liveChat: "Live Chat",
    startChatNow: "Start chat now",
    phone: "Phone",
    email: "Email",
    help: "Help",
    hours: "Support Opening Hours",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    "bank holidays": "Bank holidays",
  },
  savingTypes: {
    CURRENT_ACCOUNT: "Current Account",
    SAVINGS_ACCOUNT: "Savings Account",
    ISA: "ISA",
    OTHER: "Other",
  },
  notificationSettings: {
    settings: "Settings",
    connectionAlerts: "Connection Alerts",
    emailNotifications: "Email Notifications",
    referenceComplete: "Reference Complete",
    rtrReadyForReview: "Right To Rent Ready for Review",
    noSettingsInfoCard: {
      title: "Your email notification settings are company wide",
      subTitle:
        "Please speak to your account holder if you need to change the email notification settings",
    },
  },
  incomeSources: {
    BENEFITS: "Benefits",
    EMPLOYED: "Employed",
    NO_INCOME: "No Income",
    OTHER: "Other",
    RETIRED: "Retired",
    SELF_EMPLOYED: "Self-Employed",
    STUDENT: "Student",
  },
  screeningResult: {
    title: "Screening Result",
    fullScreening: "Comprehensive Screening",
    priorityScreening: "Priority Screening",
    instantScreening: "Essential Screening",
    emplRef: "Employment Reference",
    landlordRef: "Landlord Reference",
    outstanding: "* Comprehensive screening items still outstanding",
    guarantor: "Guarantor",
    renter: "Renter",
    referenceType: "Reference Type",
  },
  screeningTypes: {
    PRE_QUALIFICATION: "Pre-qualification",
    CC_ONLY: "Credit Check",
    INSTANT: "Essential",
    FULL: "Comprehensive",
    PRIORITY: "Priority",
  },
  paymentStatus: {
    title: "Payment status",
    status: {
      IN_PROGRESS: "In progress",
      PAID: "Paid",
      FAILED: "Payment failed",
      REFUNDED: "Refunded",
    },
  },
  depositStatus: {
    DFI: "Deposit Free",
    cash: "Cash Deposit",
    isEligible: {
      true: "Eligible",
      false: "Not Eligible",
    },
  },
  identity: {
    fullName: "Full Name",
    dateOfBirth: "Date of Birth",
    gender: "Gender",
    email: "Email Address",
    phoneNumber: "Phone Number",
    martialStatus: "Marital Status",
    nationality: "Nationality",
    experianText: "* Experian does not check Nationality",
  },
  addresses: {
    address: {label: "Address"},
    livedSince: {label: "Lived here since"},
    livedUntil: {label: "Lived here until"},
    currentAddress: {label: "Current Address"},
    flat: {label: "Flat number", placeholder: "Your flat number"},
    houseNumber: {label: "House number", placeholder: "Your house number"},
    houseName: {label: "House name", placeholder: "Your house name"},
    houseNumberOrName: {label: "House number or Name", placeholder: "Your house number or name"},
    town: {label: "Town / City", placeholder: "Your town or city"},
    street: {label: "Street", placeholder: "Your street"},
    county: {label: "County", placeholder: "Your county"},
    countryCode: {label: "Country", placeholder: "Your country"},
    postCode: {label: "Postcode", placeholder: "Your postcode"},
  },
  rentIncomePayments: {
    transactions: "Transactions",
    verified: {
      AUTOMATIC: "Open Banking",
      MANUAL: "Manual Upload",
    },
    adminBtn: {
      INCOME: "Manual Income Details",
      RENT: "Manual Rent Details",
    },
    message: {
      INCOME: {
        AUTOMATIC: {},
        MANUAL: {
          PENDING:
            "This person has uploaded proof of their income payments. Our referencing team is in the process of verifying the payments.",
          DECLINED:
            "This person has uploaded proof of their income payments. The documents did not meet our verification requirements and therefore we were unable to verify them.",
        },
      },
      RENT: {
        AUTOMATIC: {},
        MANUAL: {
          PENDING:
            "This person has uploaded proof of their rental payments. Our referencing team is in the process of verifying the payments.",
          DECLINED:
            "This person has uploaded proof of their rent payments. The documents did not meet our verification requirements and therefore we were unable to verify them.",
        },
      },
    },
    employmentStatus: "Employment Status",
    paymentFrequency: "Payment Frequency",
    salaryAmount: "Salary Amount",
    rentAmount: "Rent Amount",
    annualSalary: "Annual Salary",
  },
  navigation: {
    deposits: "Deposits",
    rentPassports: "References",
    branches: "Branches",
    users: "Users",
    system: "System Settings",
    company: "Company",
    logout: "Log Out",
    depositAgreements: "Deposit Agreements",
    teamMembers: "Team Members",
    tenancy: "Tenancy",
    jointScreening: "Household Screening",
    rightToRent: "Right to Rent",
    favourites: "Favourites",
    reports: "Reports",
    properties: "Properties",
    partnerServices: "Partner Services",
    insurance: "Insurance",
  },
  rentalPreferences: {
    title: "Rental Preferences",
    smoking: "Smoke",
    children: "Children",
    furnish: "Furnished",
    bedrooms: "Bedrooms",
    pets: "Pets",
    accessibility: "Accessibility",
    accessibilityRequirements: "Accessibility Requirements",
  },
  moveInDetails: {
    title: "Move In Details",
    editButton: "Edit",
    moveInDate: "Move in date",
    moveInAddress: "Move in address",
  },
  employment: {
    FULL_TIME: "Full-time employed",
    PART_TIME: "Part-time employed",
    EMPLOYED: "Full-time employed",
    PART_EMPLOYED: "Part-time employed",
    SELF_EMPLOYED: "Self-employed",
    STUDENT: "Student",
    RETIRED: "Retired",
    BENEFITS: "Benefits",
    OTHER: "Other",
    UNEMPLOYED: "Unemployed",
  },
  employmentType: {
    ZERO_HOUR_CONTRACT: "Zero Hour Contract",
    PERMANENT: "Permanent",
    CONTRACT: "Temporary",
  },
  considerSummary: {
    title: "Considerations",
    titleHighRisk: "Why is this person a high risk?",
    sectionKeys: {
      consider: {
        IDENTITY: "Credit Check",
        ID_VERIFICATION: "ID Verification", // Duplicate old rp
        IDENTITY_VERIFICATION: "ID Verification", // Duplicate new rp
        ADDRESS_HISTORY: "Address History",
        CREDIT_CHECK: "Credit Indicator",
        FINANCIAL_HISTORY: "Financial History",
        ACTIVE_CCJ: "Active CCJs",
        SATISFIED_CCJ: "Satisfied CCJs",
        INSOLVENCY: "Insolvency",
        INCOME: "Income",
        RENT: "Rent Payments",
        EMPLOYMENT_REFERENCE: "Employment Reference", // Dublicate old rp
        EMPLOYEE_REFERENCE: "Employment Reference", // Dublicate new rp
        LANDLORD_REFERENCE: "Landlord Reference",
        HOMEOWNER: "Homeowner",
        UK_RESIDENT: "UK Resident",
        SELF_EMPLOYED: "Self Employed",
        OVERALL: "Suspicious Activity",
      },
      highRisk: {
        IDENTITY: "Credit Check",
        ID_VERIFICATION: "ID Verification",
        ADDRESS_HISTORY: "Address History",
        CREDIT_CHECK: "Credit Indicator",
        FINANCIAL_HISTORY: "Financial History",
        ACTIVE_CCJ: "Active CCJs",
        SATISFIED_CCJ: "Satisfied CCJs",
        INSOLVENCY: "Insolvency",
        INCOME: "Income",
        RENT: "Rent Payments",
        EMPLOYMENT_REFERENCE: "Employment Reference",
        EMPLOYEE_REFERENCE: "Employment Reference", // Dublicate new rp
        LANDLORD_REFERENCE: "Landlord Reference",
        HOMEOWNER: "Homeowner",
        UK_RESIDENT: "UK Resident",
        SELF_EMPLOYED: "Self Employed",
        OVERALL: "Risk Indicators found",
      },
    },
    errorKeys: {
      consider: {
        AGE_UNDER_18: "Under 18",
        UNABLE_TO_VERIFY: "Unable to verify",
        NOT_FOUND: "Not found",
        NOT_RENTED: "Not Rented",
        HIGH_RISK: "High risk",
        VERY_POOR: "Very poor",
        ZERO_VERIFIED_ADDRESSES: "0 UK addresses verified",
        PRESENT: "Present",
        IVA: "IVA",
        BANKRUPTCY: "Bankruptcy",
        IVA_AND_BANKRUPTCY: "IVA and Bankruptcy",
        PRESENT_OVER_300: "Present - Over £300",
        REJECTED: "Rejected",
        UNABLE_TO_PROVIDE: "Unable to provide",
        NEGATIVE_FEEDBACK: "Negative feedback",
        WOULD_NOT_RENT_TO_THIS_PERSON_AGAIN: "Would not rent to this person again",
        MULTIPLE_ISSUES: "Multiple issues",
        NOT_HOMEOWNER: "Guarantor is not a homeowner",
        NO_PHOTO_ID: "No Photo ID",
        HOMEOWNER: "Homeowner",
        NOT_UK_RESIDENT: "Not UK Resident",
        CONTRACT: "Contract",
        REQUEST_REJECTED: "Request Rejected",
        NO_INCOME: "No Income",
        SELF_EMPLOYED: "Can not provide 6+ months evidence",
        LOW_MONTH_EVIDENCE: "Cannot provide 6+ months evidence",
        ZERO_HOUR_CONTRACT: "Zero Hour Contract",
        BLOCKED_FRAUD_STATUS: "Contact Support for further information",
        CONTRACT_UNDER_6_MONTHS: "Temporary - Under 6 Months",
      },
      highRisk: {
        AGE_UNDER_18: "Under 18",
        UNABLE_TO_VERIFY: "Unable to verify",
        NOT_FOUND: "Not found",
        NOT_RENTED: "Not Rented",
        HIGH_RISK: "High risk",
        VERY_POOR: "Very poor",
        ZERO_VERIFIED_ADDRESSES: "0 UK addresses verified",
        PRESENT: "Present",
        IVA: "IVA",
        BANKRUPTCY: "Bankruptcy",
        IVA_AND_BANKRUPTCY: "IVA and Bankruptcy",
        PRESENT_OVER_300: "Present - Over £300",
        REJECTED: "Rejected",
        UNABLE_TO_PROVIDE: "Unable to provide",
        NEGATIVE_FEEDBACK: "Negative feedback",
        WOULD_NOT_RENT_TO_THIS_PERSON_AGAIN: "Would not rent to this person again",
        MULTIPLE_ISSUES: "Multiple issues",
        NOT_HOMEOWNER: "Guarantor is not a homeowner",
        NO_PHOTO_ID: "No Photo ID",
        HOMEOWNER: "Homeowner",
        NOT_UK_RESIDENT: "Not UK Resident",
        CONTRACT: "Contract",
        REQUEST_REJECTED: "Request Rejected",
        NO_INCOME: "No Income",
        SELF_EMPLOYED: "Can not provide 6+ months evidence",
        LOW_MONTH_EVIDENCE: "Cannot provide 6+ months evidence",
        ZERO_HOUR_CONTRACT: "Zero Hour Contract",
        BLOCKED_FRAUD_STATUS: "Please review our summary notes for more information",
        CONTRACT_UNDER_6_MONTHS: "Temporary - Under 6 Months",
      },
    },
    footer: {
      consider:
        "Before you proceed with a tenancy agreement, you may wish to consider a guarantor, rent advance or other measures in accordance with your company guidelines",
      highRisk:
        "If you wish to proceed with a tenancy agreement, we recommend you adopt safeguarding measures in accordance with your company guidelines.",
    },
  },
  frequency: {
    WEEKLY: "Weekly",
    FORTNIGHTLY: "Fortnightly",
    TWO_WEEKLY: "Every 2 weeks",
    FOUR_WEEKLY: "Four weekly",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    OTHER: "Other",
  },
  userRoles: {
    AGENT: "Agent",
    ADMIN: "Admin",
  },
  depositType: {
    CASH_DEPOSIT: "Cash",
    DEPOSIT_FREE_INSURANCE: "DFI",
  },
  modules: {
    errorPage: {
      notFound: {
        actionText: "Back to home",
        text: "Oops, page not found",
        description: "Sorry, that page is unavailable.",
      },
      serverUnavailable: {
        title: "Sorry, server is on maintenance right now.",
        text: "It may take few minutes, please try again later",
        tryAgain: "Try Again",
      },
      unauthorized: {
        reason: "Session has expired",
      },
    },
    tenancy: {
      list: {
        title: "Tenancy",
        columnLabels: {
          branch: "Branch",
          uniqueId: "Unique ID",
          tenancyCode: "Tenancy Code",
          property: "Property",
          members: "Renter Name(s)",
          affordability: "Affordability",
          dateCreated: "Date Created",
        },
        fallbackContent: {
          title: "You currently don't have any Tenancy",
          subTitle: "Add a Tenancy Screening to manage renters as a group",
          btnText: "Add Tenancy",
        },
        actions: {
          tenancy: "Tenancy",
          view: "View",
          delete: "Delete",
        },
        deleteConfirmation: {
          title: "Delete Tenancy",
          description: "Are you sure you want to delete the Tenancy",
          successMessage: "Successfully deleted Tenancy",
          failedMessage: "Failed to delete Tenancy",
        },
      },
      details: {
        actions: {
          backToResults: "Back to Results",
          downloadReport: "Download Report",
          delete: "Delete",
          edit: "Edit",
        },
        services: {
          title: "Services",
        },
        rlpEligibility: {
          title: "Rent Guarantee and Legal Expenses",
          content:
            "15 months rent cover, £100,000 legal expenses, up to 3 months' vacant possession, no excess, and 24/7 legal helpline.",
          moreInfo: "More info",
          terms:
            "*Subject to further verification. This is preliminary eligibility and agents must confirm all conditions before purchase.",
          CTA: {
            startingFrom: "Starting from £135pa",
            premium: "Premium £",
            ipt: ", IPT £",
            purchasePolicy: "Purchase Policy",
            total: "Total £",
            purchaseWithPrice: " - Purchase Policy",
            indicative: "*This is indicative pricing as all details are not confirmed yet.",
          },
          status: {
            notEligible: {
              title: "Not Eligible",
              rentersFailed: "One or more renters have failed the ",
              eligibilityCriteria: "eligibility criteria",
              needGuarantor: " and need a guarantor.",
              withGuarantor: " with a guarantor.",
            },
            eligible: {
              title: "Eligible",
              rentersPassed: "Renters in this Tenancy have passed the eligibility criteria.",
              withGuarantor:
                "Renters in this Tenancy have passed the eligibility criteria with a guarantor.",
            },
          },
          tooltip: {
            checkHere: "check here.",
            ifYouNeedMoreInfo: "If you need more info on eligibility criteria, ",
            notStarted:
              "Tenancy is either empty or referencing is still in progress. Please add renters to see eligibility and purchase a policy. You can see eligibility once referencing is complete. ",
            notEligible: "One or more renters have failed eligibility and need a guarantor. ",
            notEligibleWithGuarantor:
              "One or more renters have failed eligibility with a guarantor. ",
          },
        },
        deleteConfirmation: {
          title: "Delete Tenancy",
          description: "Are you sure you want to delete the Tenancy",
          successMessage: "Successfully deleted Tenancy",
          failedMessage: "Failed to delete Tenancy",
        },
        reportGenerationMessage:
          "Generating report. Please don’t refresh the page, this may take a minute",
        rent: "Rent",
        asideInfo: {
          title: "Tenancy Affordability",
          titalAffo: "Total Affordability",
          fallbackText: "You currently don't have any renters attached",
          progress: {
            title: "Tenancy Progress",
            completeDialogTitle: "Complete Tenancy",
            rent: "Rent",
            step: "Step",
            pcw: "pcw",
            progressing: "Progressing",
            property: "Property",
            step1Text: "Request Sent",
            step2Text: "Referencing",
            step3Text: "Referencing Results",
            step4Text: "Reference Finalized",
            step4TextDialog: "Tenancy Finalized",
            btnText: "Are these renters moving in?",
            reportText: "Download Report",
            step1Info:
              "Tenancy process is initiated by adding, inviting, or sending connection requests to renters. Awaiting renters to start the screening process.",
            step2Info:
              "Renters/Guarantors starts their referencing process, for this step to complete all added renters/guarantors must finish their RentPassport.",
            step3Info:
              "Renters/Guarantors successfully finished their renter passport and the referencing results are generated.",
            step4Info: "The Tenancy is manually marked as finalized.",
          },
        },
        properties: {
          properties: "Properties",
          addProperties: "Add Properties",
          addPropertyDialog: {
            addProperty: "Add Property",
            search: {
              property: "Property",
              placeholder: "Search for existing property here",
              or: "Or",
              createNew: "Create a new property",
              createProperty: "Create Property",
              list: {
                noDataTitle: "No Results",
                noDataText:
                  "There is no Property found according to your request. Try updating the request or creating a new Property.",
                propertiesFound: "Properties found",
              },
            },
            rent: "Rent",
            add: "Add",
          },
          noProperties: "You currently don't have any Properties attached to this Tenancy",
          targetRent: "Target rent",
          viewDetails: "View Details",
          delete: "Delete",
          deleteConfirmation: {
            title: "Delete Property",
            description: "Are you sure you want to delete the Property",
            successMessage: "Successfully deleted Property",
            failedMessage: "Failed to delete Property",
          },
        },
        renters: {
          renters: "Renters",
          addRenters: "Add renters",
          addRentersDialog: {
            title: "Add renters",
            searchTitle: "Search for a renter that you are connected to",
            searchPlaceholder: "Enter Renter's email",
            invite: {
              title: "Or invite a renter to connect.",
              link: "Invite a new renter",
              text: "User not listed?",
              action: "Invite a new renter",
            },
            renterAlreadyAdded: "This renter has already been added.",
            done: "Done",
          },
          noRenters: "You currently don’t have any renters attached to this Tenancy",
          screeningResult: "Screening result",
          rentShare: "Rent Share",
          affordability: "Affordability",
          guarantor: "Guarantor",
          inviteSent: "Invite Sent",
          guarantorReplacement: "Guarantor Replacement",
          pcm: "pcm",
          remove: "Remove",
          totals: "Totals",
          successfullyDeleted: "Renter successfully unlinked from Tenancy",
          failedToDelete: "Failed to unlink renter from Tenancy",
        },
      },
      manageTenancy: {
        createTitle: "Create Tenancy",
        editTitle: "Edit Tenancy",
        branchLabel: "Branch",
        branchPlaceholder: "Select branch",
        propertyLabel: "Property Address",
        searchPlaceholder: "Search for property address",
        detailsLabel: "Details",
        internalCodeLabel: "Internal Tenancy Code",
        typeHere: "Type here",
        rentLabel: "Rent",
        frequencyLabel: "Rent Frequency",
        frequencyPlaceholder: "Select frequency",
        amountLabel: "Amount",
        propertiesFound: "Properties Found",
        branchMessage: "You need to select a branch to Create a Property",
        createButton: "Create",
        editButton: "Save",
        errorPropertyInList: "Property already added",
      },
    },
    jointScreening: {
      list: {
        columnLabels: {
          address: "Address",
          internalCode: "Household Code",
          renterName: "Renter Name(s)",
          totalAffordability: "Total Affordability",
          dateCreated: "Date Created",
        },
        fallbackContent: {
          title: "You currently don't have any Household Screenings",
          subTitle: "Add a Household Screening to manage renters as a group",
          btnText: "Add Household Screening",
        },
      },
      filter: {
        labels: {
          screeningStatus: "Screening Status",
        },
      },
      details: {
        marketplaceIframeTitle: "Partner Services Home",
        monthlyRent: "Monthly Rent",
        weeklyRent: "Weekly Rent",
        rent: "Rent",
        downloadScreening: "Download Screening",
        inviteDeclined: "Invite Declined",
        inviteSent: "Invite Sent",
        connectionDeclined: "Connection Declined",
        rentersInfo: {
          guarantor: "guarantor",
          title: "Renters",
          totals: "Totals",
          affordability: "Affordability",
          btnText: "Add Renter",
          fallBackCard: {
            title: "You currently don't have any renters attached",
            subTitle: "You currently don’t have any renters attached to this household reference.",
          },
        },
        guarantorInfo: {
          title: "Need a guarantor?",
          subText:
            "Speak to your renters to find out who will be providing a guarantor and request a guarantor from that renters passport page.",
        },
        partnerServices: {
          title: "Partner services",
          subText:
            "Canopy have negotiated great deals with the following partners to help Agents and Landlords alike",
        },
        asideInfo: {
          title: "Household Affordability",
          titalAffo: "Total Affordability",
          fallbackText: "You currently don't have any renters attached",
          progress: {
            title: "Household Progress",
            completeDialogTitle: "Complete Household Reference",
            rent: "Rent",
            step: "Step",
            pcw: "pcw",
            progressing: "Progressing",
            property: "Property",
            step1Text: "Request Sent",
            step2Text: "Referencing",
            step3Text: "Referencing Results",
            step4Text: "Reference Finalized",
            step4TextDialog: "Household Reference Finalized",
            btnText: "Are these renters moving in?",
            reportText: "Download Report",
            step1Info:
              "Household reference process is initiated by adding, inviting, or sending connection requests to renters. Awaiting renters to start the screening process.",
            step2Info:
              "Renters/Guarantors starts their referencing process, for this step to complete all added renters/guarantors must finish their RentPassport.",
            step3Info:
              "Renters/Guarantors successfully finished their renter passport and the referencing results are generated.",
            step4Info: "The household reference is manually marked as finalized.",
          },
        },
      },
      notificationBox: {
        title: "You are currently in",
        subTitle: "Change branch to see household screening.",
      },
      dialogs: {
        viewScreening: "View Screening",
        editJS: {
          edit: "Edit",
          deleteTitle: "Household Screening deletion",
          description: "Are you sure you want to delete the household screening called ",
        },
        addJointScreening: {
          title: "Create Household Screening",
          titleEdit: "Edit Household Screening",
          screeningNameTitle: "Screening Info",
          screeningSubTitle: "Please fill in at least one of the fields below to proceed",
          refName: "Name the household screening",
          refNamePlaceholder: "Type here",
          refAddress: "Property address",
          refAddressPlaceholder: "Address Lookup",
          refCode: "Internal code",
          refCodePlaceholder: "Type here",
          rentTitle: "Rent",
          rentFrequency: "Rent Frequency",
          amount: "Amount",
          chooseBranch: "Select Branch",
        },
        addRenterToJointScreening: {
          title: "Add renter",
          searchTitle: "Search for a renter that you are connected to",
          searchPlaceholder: "Enter Renter's email",
          inviteTitle: "Or invite a renter to connect.",
          inviteLink: "Invite a new renter",
          inviteText: "User not listed?",
          inviteAction: "Invite a new renter",
          renterTitle: "Renter",
          rentShareTitle: "Rent share",
          rentFrequency: "Rent frequency",
          percentage: "Percentage",
          typeHere: "Type here",
        },
      },
    },
    auth: {
      loginPage: {
        heading: "Sign in",
        actionText: "Not got an account yet?",
      },
      ghostMode: {
        exit: "Exit",
        problemText: "There was a problem entering Ghost Mode",
        backToAdmin: "Back to Canopy Admin",
        ghostMode: "Ghost View",
      },
      recoveryPage: {
        request: {
          heading: "Forgotten Your Password?",
          verifyHeading: "Verify Email",
          text: "Enter your email address to reset your password.",
          sendTo: "We've sent an email to",
          checkInbox: "Please check your inbox and follow the instructions in the email.",
          notReceived: "Not received an email?",
        },
        processing: {
          heading: "Reset Password",
          successHeading: "You've reset your password",
          text: "Your password must include one symbol and be at least 8 characters long.",
          successText: "Success! Sign in with your new password to use Canopy HQ.",
        },
      },
      registrationPage: {
        request: {
          heading: "Create Account",
          verifyHeading: "Verify your email",
          emailSignUpText:
            "Sign up to our emails for updates, product releases, and tips to help your business.",
          emailSignUpCheckbox: "Sign up to Canopy emails",
          actionText: "Already got an account?",
          dialog: {
            title: "Important Stuff",
            description: "Please read these carefully before accepting.",
            links: {
              PRIVACY_POLICY: "Privacy policy",
              TERMS_AND_CONDITIONS: "Terms and conditions",
            },
          },
          sendTo: "We've sent an email to",
          checkInbox: "Please check your inbox and follow the instructions in the email.",
          notReceived: "Not received an email?",
        },
        verify: {
          heading: "Verify your email address",
          successHeading: "Email successfully verified!",
          wrongTokenText: "Used token is not valid, please check latest email with correct token.",
          expiredTokenText: "Token has been expired.",
          successText:
            "Thanks for verifying. You can now continue creating your Canopy HQ account.",
          failText:
            "It looks like you're already verified, so please try logging in. If you're still having problems, please contact the Canopy support team.",
        },
        setup: {
          heading: "Account Setup",
          userTypeHeading: "What type of user are you?",
          landlord: "Private Landlord",
          company: "Company",
          userTypes: {
            landlord: "Private Landlord",
            company: "Company",
          },
          detailHeading: {
            landlord: "Your details",
            company: "Company Details",
          },
          dialog: {
            title: "Company Terms & Conditions",
            description:
              "Please agree with the following terms and conditions in order to proceed:",
            checkbox: "I agree to the Terms and Conditions listed above",
          },
          addressHeading: "Address Details",
        },
      },
      companyNotFoundPage: {
        header: "Account Setup",
        title: "We can't find a company that invited you to join Canopy",
        text:
          "Most probably you have created an account using an email address that is different to the email address you were invited.",
      },
      formFields: {
        email: {
          label: "Email address",
          placeholder: "Type email address here",
        },
        password: {
          label: "Password",
          placeholder: "Type password here",
        },
        firstName: {
          label: "First name",
          placeholder: "Type your first name here",
        },
        lastName: {
          label: "Last name",
          placeholder: "Type your last name here",
        },
        companyName: {
          label: "Company name",
          placeholder: "Type your company name",
        },
        registrationNumber: {
          label: "Companies House registration Number",
          placeholder: "Type your CRN here",
        },
        companyPhoneNumber: {
          label: "Company phone number",
          placeholder: "Your company phone number",
        },
        phoneNumber: {
          label: "Phone Number",
          placeholder: "Your phone number",
        },
      },
      common: {
        forgotPassword: "Forgotten Password?",
        resendVerificationEmail: "Resend verification email",
        verificationEmailResented: "Message has been re-sent",
        changeEmailAddress: "Change email address",
        resetPassword: "Reset password",
        createAccount: "Create Account",
        contactOurSalesTeam: "Contact our Sales Team",
        signIn: "Sign in",
        next: "Next",
        saveNContinue: "Save & Continue",
        invitedYouToJoin: "invited you to join",
      },
    },
    branches: {
      form: {
        branchDetails: "Branch details",
        addressDetails: "Address Details",
        branchFields: {
          name: {label: "Branch name", placeholder: "Type branch name here"},
          phone: {label: "Branch phone number", placeholder: "Type branch phone number here"},
        },
      },
      list: {
        header: "Branches",
        dialog: {
          title: {
            edit: "Branch Settings",
            add: "Add Branch",
          },
        },
        columnLabels: {
          name: "Name",
          address: "Address",
          users: "Team Members",
          passports: "Passports",
          deposits: "Deposits",
          dateAdded: "Date Added",
        },
        fallbackContent: {
          title: "You currently don't have any branches",
          subTitle: "Add a branch to be ables to organise your users access.",
          btnText: "Add Branch",
        },
      },
      details: {
        users: "Team Members",
        rentPassports: "Rent passports",
        deposits: "Deposits",
        back: "Back to Results",
        editBranch: "Edit Branch",
        viewBranch: "View Branch",
        filter: {
          labels: {
            status: "Status",
          },
        },
      },
    },
    company: {
      marketplaceIframeTitle: "Partner Services Home",
      jointScreening: "Household Screening",
      tenancy: "Tenancy",
      users: "Users",
      branches: "branches",
      rentPassports: "Rent passports",
      deposits: "Deposits",
      accountSettings: "Account settings",
      partners: "Partner Services",
      otherServices: "Other Services",
      partnersText:
        "Canopy have negotiated great deals with the following partners to help Agents and Landlords alike",
    },
    users: {
      inviteForm: {
        userDetails: "Team Member details",
        branches: "Branches",
        selectAll: "Select all",
        userFields: {
          email: {label: "Email address", placeholder: "Type email address here"},
          userType: {label: "Role", placeholder: "Choose Role"},
        },
      },
      dialogs: {
        invite: {
          title: "Invite Team Member",
          userTitle: "Team Member details",
          userDetails: "Team Member details",
          branchTitle: "Branches",
          successTextStart: "The Team Member will receive an email invite and join",
          successTextEnd: "Canopy HQ as soon as they sign up.",
          titleSent: "Team Member successfully invited!",
          emailLabel: "Email address",
          emailPlaceholder: "Type email address here",
          userTypeLabel: "Role",
          userTypePlaceholder: "Choose Role",
        },
        userActivity: {
          title: "Are you sure?",
          textInactive:
            "Setting this user to inactive will stop them from being able to log in to their account.",
          textActive:
            "Setting this user to active will restore the possibility to log in to their account.",
        },
        editUser: {
          title: "Edit Team Member Details",
          firstNameLabel: "First Name",
          lastNameLabel: "Last Name",
          emailLabel: "Email address",
        },
      },
      list: {
        header: "Users",
        columnLabels: {
          name: "Name",
          status: "Status",
          email: "Email",
          userType: "Role",
          branches: "Branches",
          passports: "Passports",
          deposits: "Deposits",
          dateAdded: "Date Added",
          dateInvited: "Date Invited",
        },
        modals: {
          resend: {
            title: "Are you sure to resend invite?",
            text: "Please confirm you want to resend the invite to",
            primaryBtnText: "Resend invite",
            secondaryBtnText: "Not now",
          },
          cancel: {
            title: "Are you sure to cancel invite?",
            text: "Please confirm you want to rescind the invite to",
            primaryBtnText: "Confirm invite cancellation",
            secondaryBtnText: "Not now",
          },
        },
        popoverLabels: {
          resend: "Resend Invite",
          cancel: "Cancel Invite",
        },
        fallbackContent: {
          title: "You are the only Team Member",
          invitedTitle: "You haven't invited any Team Members yet",
          subTitle: "Invite colleagues from your organization to join Canopy HQ.",
          btnText: "Invite Team Member",
        },
      },
      filter: {
        labels: {
          userType: "Role",
          branches: "Branches",
          dateAdded: "Date Added",
        },
      },
      details: {
        rentPassports: "Rent passports",
        deposits: "Deposits",
        editUser: "Edit User",
        deleteUser: "Delete User",
      },
      tab: {
        live: "Live Team Members",
        invited: "Invited",
      },
    },
    favourites: {
      title: "Favourites",
      notCompleted: "Comprehensive screening not complete",
      tab: {
        rentPassports: "Rent Passports",
      },
      fallbackContent: {
        title: "You have no favourites to see",
        subTitle:
          "To mark any Rent Passport as a favourite just tap the star icon in any list, or at the top of the Rent Passport itself.",
      },
    },
    rentPassport: {
      title: "Rent Passport",
      notCompleted: "Comprehensive screening not complete",
      dialogs: {
        RPScreeningInfo: {
          sectionData: {
            NOT_STARTED: "Not started",
            OPEN_BANKING: "Open banking",
            HIGH_RISK: "High risk",
            MANUAL: "Manual",
            ACCEPT: "Accept",
            WITH_CONSIDERATIONS: "with considerations",
            IN_PROGRESS: "In progress",
            CONSIDER: "Accept",
            CONSIDER_WARN: "Consider",
            UNABLE_TO_VERIFY: "Unable to verify",
            UNABLE_TO_PROVIDE: "Unable to provide",
            ZERO_VERIFIED: "0 Verified",
            ZERO_VERIFIED_NOT_LIVE_UK: "0 Verified",
            VERIFIED: "Verified",
            EXCELLENT: "Excellent",
            GOOD: "Good",
            FAIR: "Fair",
            POOR: "Poor",
            ZERO_ISSUE: "0 Issues",
            VERY_POOR: "Very Poor",
            VERY_POOR_CONSIDER: "Very Poor",
            NOT_FOUND: "Not Found",
            WARNING: "Warning",
            REJECTED: "Rejected",
            HOMEOWNER: "Homeowner",
            GUARANTOR_HOMEOWNER: "Homeowner",
            CONTRACT: "Contract",
            NOT_LIVE_UK: "Not lived in the UK",
            R2R_NOT_STARTED: "Not started",
            REQUEST_DECLINED: "Request Declined",
            COMPLETE: "Complete",
            PROVIDE_IN_PERSON: "Provide In Person",
            POTENTIAL_FRAUD: "Potential Fraud",
            EXPIRES_IN_3_MONTHS: "Expires In 3 Months",
            EXPIRES_TODAY: "Expires Today",
          },
          navigation: {
            intro: "Intro",
            screeningResult: "Screening Result",
            idVerification: "ID Verification",
            creditCheck: "Credit Check",
            income: "Income",
            savings: "Savings",
            employmentReference: "Employment Reference",
            rentPayments: "Rent Payments",
            landlordReference: "Landlord Reference",
            rightToRent: "Right To Rent",
          },
          title: "Canopy Referencing Rules",
          subTitle: "Understanding the referencing report",
          description:
            "Canopy has created its one of a kind Rent Passport providing you, the landlords and letting agents with transparent, accurate and real-time information in order to ensure that the best tenants are able to move into suitable properties as soon as possible. Canopy has partnered with Experian Credit Bureau to provide identity and financial history on the screened renters. Canopy further makes use of market-leading open banking technology to generate real-time and accurate affordability calculations for the screened renters.",
          helpTitle: "Need further help?",
          helpDescription:
            "For further explanation of anything to do with the Canopy referencing product then please don’t hesitate to contact our support team.",
          helpContacts: {
            phone: "Phone",
            email: "Email support",
          },
          sections: {
            idVerification: {
              description:
                "When an ID Verification is purchased the person will receive a request to prove their Identity. Our ID verification partner will verify their ID automatically.",
              tables: [
                {
                  sections: {
                    R2R_NOT_STARTED: "You have requested this person to provide proof of identity.",
                    IN_PROGRESS:
                      "This person has submitted their ID and we are waiting on our Identity Partner to verify their document.",
                    VERIFIED:
                      "This person has provided a valid form of Photo ID and has now been verified.",
                    CONSIDER_WARN:
                      "This person is not able to provide a Photo ID to verify their identity.",
                    HIGH_RISK:
                      "Our Identity Partner has flagged something suspicious about the documents this person has provided.",
                  },
                },
              ],
            },
            savings: {
              description:
                "The renter fills in their savings information, including the amount they would like to contribute towards rent over the next 12 months. Canopy person’s rental affordability. ",
              tables: [
                {
                  name: "Canopy assess a renters’ savings through the following 2 options: ",
                  sections: {
                    OPEN_BANKING:
                      "The person grants Canopy read-only access to their bank transaction data. From this bank selection, Canopy identifies the monthly closing balance for the previous 12 month period.",
                    MANUAL:
                      "The person submits 3+ months of bank statements as proof of their savings which are then manually verified by our specialist referencing team.",
                  },
                },
                {
                  name: "Open Banking Savings Results",
                  sections: {
                    VERIFIED:
                      "We were able to identify 3+ months of savings from within this person’s connected bank account.",
                  },
                },
                {
                  name: "Manual Upload Savings Results",
                  sections: {
                    IN_PROGRESS:
                      "This person has uploaded documents to prove their savings. Our referencing team is in the process of verifying the documents.",
                    VERIFIED:
                      "This person has uploaded documents to prove their savings. Our referencing team has validated the documents.",
                    UNABLE_TO_VERIFY: {
                      text:
                        "This person has uploaded documents to prove their savings. The documents did not meet our verification requirements and therefore we were unable to verify them.",
                      title: "Common reasons for this include:",
                      list: [
                        "Too few or incorrect documents uploaded",
                        "Uploaded images were low resolution or unclear",
                      ],
                    },
                  },
                },
              ],
            },
            screeningResult: {
              description:
                "This is the overall status / result of the person you are referencing. Below is a table of all of the statuses, what they mean and suggestions on how you may want to proceed.",
              tables: [
                {
                  title: "Results Table",
                  sections: {
                    NOT_STARTED:
                      "The person has not started completing any of the sections of their Rent Passport.",
                    IN_PROGRESS:
                      "This person is still in the process of completing their Rent Passport.",
                    ACCEPT:
                      "This person has completed all the sections of their Rent Passport and we have not found any issues.",
                    CONSIDER:
                      "This person has completed all the sections of their Rent Passport and we have highlighted a few issues which you should consider before proceeding with a tenancy agreement.",
                    HIGH_RISK:
                      "This person has completed all the sections of their Rent Passport and we have found some issues, which indicate the person is potentially a high risk tenant.",
                  },
                },
              ],
            },
            creditCheck: {
              description:
                "The renter fills in their identity information, including name, date of birth, phone number and 3+ years of UK address history. We then utilise Experian’s credit data to cross reference and verify such information. All references to a “credit profile” refers to a credit profile provided to Canopy by the Experian Consumer Credit Bureau. ",
              tables: [
                {
                  sections: {
                    VERIFIED: "A credit profile has been found for this person.",
                    UNABLE_TO_VERIFY:
                      "We were unable to find a credit profile for this person. This is either due to the person not having lived in the UK during the previous 12 months or Experian not having enough data to create a credit profile.",
                  },
                },
                {
                  title: "Address History",
                  sections: {
                    VERIFIED:
                      "We matched this person’s credit profile with the address information provided by the person.",
                    ZERO_VERIFIED: {
                      text:
                        "A credit profile was found for this person but no UK addresses could be matched with this person’s credit profile.",
                      title: "Common reasons for this include:",
                      list: [
                        "This person has no history of tenancy bills associated with the submitted addresses.",
                        "Experian does not have enough data on this person.",
                        "This person could only be matched to non UK addresses.",
                      ],
                    },
                    UNABLE_TO_VERIFY:
                      "A credit profile could not be found for this person and therefore we were unable to verify any of their addresses.",
                    ZERO_VERIFIED_NOT_LIVE_UK:
                      "This person has not lived in the UK for at least 12 months and therefore we were unable to verify any of their addresses.",
                  },
                },
                {
                  title: "Credit Indicator",
                  sections: {
                    EXCELLENT:
                      "A credit profile was found for this person. Their credit score is rated as excellent.",
                    GOOD:
                      "A credit profile was found for this person. Their credit score is rated as good.",
                    FAIR:
                      "A credit profile was found for this person. Their credit score is rated as fair.",
                    POOR:
                      "A credit profile was found for this person. Their credit score is rated as poor.",
                    VERY_POOR_CONSIDER:
                      "A credit profile was found for this person. Their credit score is rated as very poor. This indicates a high risk tenant.",
                    NOT_FOUND:
                      "We were unable to find a credit profile for this person. This is either due to the person not having lived in the UK during the previous 12 months or Experian not having enough data to create a credit profile.",
                  },
                },
                {
                  title: "Financial Summary",
                  sections: {
                    ZERO_ISSUE:
                      "A credit profile was found for this person. Their credit score is rated as excellent.",
                    UNABLE_TO_VERIFY:
                      "We were unable to find a credit profile for this person. This is either due to the person not having lived in the UK during the previous 12 months or Experian not having enough data to create a credit profile.",
                    WARNING: {
                      text:
                        "A Credit profile was found for this person and issues have been raised which indicate this person may be a high risk.",
                      title: "The Issues raised are one or more of the following:",
                      list: [
                        "Active CCJs",
                        "Satisfied CCJs totalling over £300",
                        "Unfulfilled Bankruptcy",
                        "IVA (Individual Voluntary Arrangement)",
                      ],
                    },
                  },
                },
              ],
            },
            income: {
              description:
                "The renter fills in their income information, including amount and frequency of received payments in order for Canopy to calculate the person’s rental affordability. ",
              tables: [
                {
                  name: "Canopy assess a renters’ income through one of the following 2 options:",
                  sections: {
                    OPEN_BANKING:
                      "The person grants Canopy read-only access to their bank transaction data. The person selects their most recent income payment. From this selection, Canopy identifies all relevant income transactions for the previous 12 month period.",
                    MANUAL: {
                      text:
                        "The person submits proof of their income by uploading documents which are manually verified by our specialist referencing team.",
                      title: "Acceptable documents include:",
                      list: ["3+ months of bank statements", "3+ months of payslips"],
                    },
                  },
                },
                {
                  name: "Open Banking Income Results",
                  sections: {
                    VERIFIED:
                      "We were able to identify 3+ months of consistent income payments from within this person’s connected bank account.",
                  },
                },
                {
                  name: "Manual Upload Income Results",
                  sections: {
                    IN_PROGRESS:
                      "This person has uploaded documents to prove their income. Our referencing team is in the process of verifying the documents.",
                    VERIFIED:
                      "This person has uploaded documents to prove their income. Our referencing team has validated the documents.",
                    UNABLE_TO_VERIFY: {
                      text:
                        "This person has uploaded documents to prove their income. The documents did not meet our verification requirements and therefore we were unable to verify them.",
                      title: "Common reasons for this include:",
                      list: [
                        "Too few or incorrect documents uploaded",
                        "Uploaded images were low resolution or unclear",
                      ],
                    },
                  },
                },
              ],
            },
            employmentReference: {
              description:
                "When a Comprehensive Rent Passport is purchased i.e. “Comprehensive screening” is requested. The person will receive a request to provide their employer’s details. An employment reference request is sent to the employer. Once the reference is completed by the employer it is  manually verified by our specialist referencing team.",
              tables: [
                {
                  sections: {
                    IN_PROGRESS: {
                      text:
                        "A reference request has been sent to the person’s employer. The progress of this reference can be seen in the employment reference section of the Rent Passport.",
                      title: "In-progress stages are as follows:",
                      list: [
                        "We are waiting for the employer to accept the reference request.",
                        "We are waiting for the employer to complete the reference.",
                        "This person’s employer has completed the reference. Our referencing team is in the process of verifying the reference.",
                      ],
                    },
                    VERIFIED:
                      "The employer has uploaded a reference for this person. Our referencing team has verified that the reference is from the person’s legitimate employer and meets our verification requirements.",
                    CONTRACT:
                      "The employer has uploaded a reference for this person, which our referencing team has verified. You may wish to consider that this person is on a temporary contract, which may indicate a potentially unstable employment.",
                    UNABLE_TO_VERIFY: {
                      text:
                        "The employer has uploaded a reference for this person. The reference did not meet our verification requirements and therefore we were unable to verify it.",
                      title: "Common reasons for this include:",
                      list: [
                        "Unable to verify company’s legitimacy",
                        "Unable to verify referee within the company",
                      ],
                    },
                    UNABLE_TO_PROVIDE: {
                      text:
                        "This person does not have an employer, so is unable to provide an employment reference.",
                      title: "This is due to the person being either:",
                      list: ["Self Employed", "Student", "Retired", "Unemployed"],
                    },
                    REJECTED: {
                      text:
                        "This person has sent a reference request to their employer. The reference request was rejected by the employer.",
                      title: "Common reasons for this include:",
                      list: [
                        "The person incorrectly entered their employers’ contact details",
                        "The person requested a reference from someone in the company who does not have authority to provide a reference",
                      ],
                    },
                    WARNING:
                      "The employer has uploaded a reference for this person, which our referencing team has verified. The reference contained negative feedback, which indicates this person is potentially a high risk tenant.",
                  },
                },
              ],
            },
            rentPayments: {
              description:
                "The renter fills in their rental transaction information, including amount and frequency of rental payments in order to display a history of consistent payment of rent.",
              tables: [
                {
                  name:
                    "Canopy assess a renters’ rental payments through one of the following 2 options:",
                  sections: {
                    OPEN_BANKING:
                      "The person grants Canopy read-only access to their bank transaction data. The person selects their most recent rental payment. From this selection, Canopy identifies all relevant rental transactions for the previous 12 month period.",
                    MANUAL:
                      "The person submits 3+ months of bank statements as proof of their rent payments which are then manually verified by our specialist referencing team.",
                  },
                },
                {
                  name: "Open Banking Rent Payment Results",
                  sections: {
                    VERIFIED:
                      "We were able to identify 3+ months of consistent rent payments from within this person’s connected bank account.",
                  },
                },
                {
                  name: "Manual Upload Rent Payment Results",
                  sections: {
                    IN_PROGRESS:
                      "This person has uploaded documents to prove their income. Our referencing team is in the process of verifying the documents.",
                    VERIFIED:
                      "This person has uploaded documents to prove their income. Our referencing team has validated the documents.",
                    UNABLE_TO_VERIFY: {
                      text:
                        "This person has uploaded documents to prove their income. The documents did not meet our verification requirements and therefore we were unable to verify them.",
                      title: "Common reasons for this include:",
                      list: [
                        "Too few or incorrect documents uploaded",
                        "Uploaded images were low resolution or unclear",
                      ],
                    },
                  },
                },
                {
                  name: "Other Results",
                  sections: {
                    UNABLE_TO_VERIFY: {
                      text: "This person has not rented in the UK within the last 12 months.",
                      title: "Common reasons for this include:",
                      list: [
                        "Person is a first time renter",
                        "Person is new to the UK and this is their first rental within the UK market",
                      ],
                    },
                    HOMEOWNER:
                      "This person has stated that they are  a homeowner and therefore does not have a recent rental history.",
                  },
                },
                {
                  name: "Guarantor Specific Results",
                  sections: {
                    GUARANTOR_HOMEOWNER: "This person has stated they are a homeowner.",
                  },
                },
              ],
            },
            landlordReference: {
              description:
                "When a Comprehensive Rent Passport is purchased i.e. “Comprehensive screening” is requested. The person will receive a request to provide their landlord’s details. An landlord reference request is sent to the landlord. Once the reference is completed by the landlord it is  manually verified by our specialist referencing team.",
              tables: [
                {
                  name: "Landlord Reference Results Table",
                  sections: {
                    IN_PROGRESS: {
                      text:
                        "A reference request has been sent to the person’s landlord. The progress of this reference can be seen in the employment reference section of the Rent Passport.",
                      title: "In-progress stages are as follows:",
                      list: [
                        "We are waiting for the landlord to accept the reference request.",
                        "We are waiting for the landlord to complete the reference.",
                        "This person’s landlord has completed the reference. Our referencing team is in the process of verifying the reference.",
                      ],
                    },
                    VERIFIED:
                      "The landlord has uploaded a reference for this person. Our referencing team has verified that the reference is from the person’s landlord and meets our verification requirements.",
                    REJECTED: {
                      text:
                        "This person has sent a reference request to their landlord. The reference request was rejected by the landlord.",
                      title: "Common reasons for this include:",
                      list: ["The person incorrectly entered their landlord’s contact details"],
                    },
                    UNABLE_TO_VERIFY: {
                      text:
                        "The landlord has uploaded a reference for this person. The reference did not meet our verification requirements and therefore we were unable to verify it.",
                      title: "Common reasons for this include:",
                      list: ["Unable to verify landlord’s legitimacy"],
                    },
                    UNABLE_TO_PROVIDE:
                      "This person has not rented in the UK in the past 3 years and therefore can not provide a landlord reference.",
                    HOMEOWNER:
                      "This person has stated that they are a homeowner and therefore does not have a recent landlord to provide a reference.",
                    WARNING: {
                      text:
                        "The landlord has uploaded a reference for this person, which our referencing team has verified. The reference contained negative feedback, which indicates this person is potentially a high risk tenant.",
                      title: "The Issues raised are one or more of the following:",
                      list: [
                        "There was damage to the property",
                        "This person has rent arrears",
                        "The landlord has stated they would not be willing to rent to this person again",
                      ],
                    },
                  },
                },
                {
                  name: "Guarantor Specific Results",
                  sections: {
                    GUARANTOR_HOMEOWNER: "This person has stated they are a homeowner.",
                  },
                },
              ],
            },
            rightToRent: {
              description:
                "When a Right to Rent check is purchased the person will receive a request to provide evidence of their right to rent in the UK. Our ID verification partner will verify their ID automatically and all documentation will be stored within your Canopy HQ. As per current government legislation all Right to Rent checks must be completed by the agent or landlords.",
              tables: [
                {
                  sections: {
                    R2R_NOT_STARTED:
                      "You have requested this person to provide proof of their Right To Rent.",
                    REQUEST_DECLINED: "This person has declined your request.",
                    VERIFIED:
                      "This person has submitted their ID for verification by our Identity partner. They have provided a valid form of photo ID. You are ready to complete this persons Right To Rent check.",
                    COMPLETE: "You have completed and stored this persons Right To Rent check.",
                    PROVIDE_IN_PERSON:
                      "This person would like to submit their proof of Right To Rent in person.",
                    POTENTIAL_FRAUD:
                      "This person has submitted their ID for verification. Our Identity partner has flagged something suspicious about this person or the documents they have provided.",
                    EXPIRES_IN_3_MONTHS:
                      "This persons proof of their Right To Rent will be deleted in 3 months. Edit the tenancy date if you need to keep hold of this proof for longer.",
                    EXPIRES_TODAY:
                      "This persons proof of their Right To Rent will be deleted today. Edit the tenancy date if you need to keep hold of this proof for longer.",
                  },
                },
              ],
            },
          },
        },
        resendInviteDialog: {
          title: "Are you sure to re-invite this user?",
          text: "Do you want to re-invite ",
        },
        editMoveInDialog: {
          title: "Edit Move In Details",
          moveInDate: "Move in date",
          moveInDateLabel: "Leave blank if not known",
          exact: "Exact",
          range: "Range",
          exactDateLabel: "Exact move in date",
          from: "From",
          to: "To",
          moveInAddress: "Move in address",
          moveInAddressLabel: "Property address",
          moveInAddressPlaceholder: "Type the full UK postcode",
          save: "Save",
        },
        requestRP: {
          title: {
            archived: "Already Connected",
            request: "Request Rent Passport",
            requestGuarantor: "Request Guarantor",
            sent: "Rent Passport Request Sent",
            requestGuarantorSent: "Request Guarantor Sent",
          },
          selectBranch: "Select branch",
          inviteByLink: {
            title: "Invite by link",
            copy: "Copy",
          },
          inviteByEmail: {
            title: "Invite by email",
            firstName: {
              label: "First Name",
              placeholder: "Type here",
            },
            lastName: {
              label: "Last Name",
              placeholder: "Type here",
            },
            phone: {
              label: "Phone Number",
              placeholder: "+447517618456",
            },
            email: {
              label: "Email Address",
              placeholder: "email@provider.com",
            },
          },
          moveInDetails: {
            title: "Move in details",
            moveInDateTitle: "Move in date",
            moveInDateHint: "Leave blank if not known",
            exact: "Exact",
            range: "Range",
            moveExactLabel: "Exact move in date",
            moveFromLabel: "From",
            moveToLabel: "To",
            movingSoon: "Moving soon! Fast-track your reference with Priority.",
            moveInAddressTitle: "Move in address",
            moveInAddressLabel: "Property Address",
            moveInAddressPlaceholder: "Type the full UK postcode",
            errorPropertyInList: "Property already added",
          },
          disclaimer:
            "We have consent from the prospective tenant for Canopy to contact them by email and phone number to complete their Rent Passport.",
          screeningSettings: {
            title: "Screening Settings",
            tooltipLink: {
              text: "For more information, please visit: ",
              linkText: "Help Centre",
            },
            free: "£ FREE",
            vat: "VAT",
            screeningOptions: {
              CC_ONLY: {
                label: "Credit Check",
                captionsTitle: "Everything in prequal screening",
                captions: ["+ Experian credit check"],
                tooltipText: [
                  "This reference tier includes an Experian credit check to assess the applicant’s creditworthiness. This check delves into a detailed financial summary, highlighting key financial aspects of the applicant’s history. This thorough examination offers insights into the applicant’s financial reliability, reflecting their ability to meet rental commitments.",
                ],
              },
              PRE_QUALIFICATION: {
                label: "Pre-qualification",
                captions: [
                  "Qualify applicants in minutes and invest your time in the best candidates",
                ],
                tooltipText: [
                  "Collect background information from prospective applicants including self-certified income sources, savings, address history, adverse credit awareness and rental preferences.",
                ],
              },
              INSTANT: {
                label: "Essential Screening",
                captionsTitle: "Everything in prequal screening",
                captions: [
                  "+ Credit check, ID verification, income and savings verification, reference report",
                ],
                captionsWithoutIDV: [
                  "+ Credit check, income and savings verification, reference report",
                ],
                captionsFree: [
                  "+ We will chase applicants on your behalf.",
                  "+ Manual documents review when required.",
                ],
                tooltipText: [
                  "Unlock a verified tenant reference. Our Essential screening includes everything needed to evaluate the suitability of most applicants for a given property.",
                ],
              },
              FULL: {
                label: "Comprehensive Screening",
                captionsTitle: "Everything in essential screening",
                captions: ["+ Employment and landlord references"],
                tooltipText: [
                  "Our Comprehensive screening unlocks employment and landlord references for applications that require a more thorough background check. Our UK-based customer operations team will collect references on your behalf and verify your applicant's employment and suitability as a tenant.",
                ],
              },
              PRIORITY: {
                label: "Priority Screening",
                captionsTitle: "Everything in comprehensive screening",
                captions: ["+ A dedicated case handler to support and fast-track your reference"],
                message: {
                  part1: "Up to",
                  part2: "41%",
                  part3: "faster",
                },
                recommendedMessage: {
                  part1: "Recommended:",
                  part2: "Upgrade to Priority to benefit from our enhanced chaser process",
                },
                tooltipText: [
                  "Our Priority tier is targeted at applicants who need expediting or may require more support given their circumstances.",
                  "Our UK-based customer operations team will allocate a dedicated case handler to support the applicant throughout the process.",
                ],
              },
            },
            addonsOptions: {
              GUARANTOR: {
                label: "Guarantor Needed",
                tooltipText: [
                  "We offer guarantor references for applicants who are unable to meet the referencing criteria alone.",
                  "Guarantors must be referenced through this service, as our guarantor referencing logic differs from renter logic. Requesting a regular tenant reference for a guarantor will lead to inaccurate references as well as violating our terms of service.",
                ],
              },
              IDV: {
                label: "ID Verification",
                caption: "Enhanced AML & KYC checks",
                tooltipText: [
                  "Verify your applicant's identity using banking-grade biometric and liveness checks. We support identity documents from over 195 countries.",
                  "Coupled with credit check data, this service unlocks advanced Know Your Customer (KYC) and Anti-Money Laundering (AML) checks which substantially reduce the risk of fraud.",
                ],
              },
              RTR: {
                label: "Right To Rent",
                caption:
                  "Check an applicant's right to rent prior to reviewing documents in person.",
                tooltipText: [
                  "This service enables our partners to collect ‘right to rent’ documents and share codes. It unlocks the ability to keep track of documents that have been verified in person and sends automated reminders when an applicant's right to rent is due to expire.",
                  "We will also retain copies of documents on your behalf and delete them on expiry in accordance with Data Protection Regulation, including GDPR.",
                  "Please note this service does not replace the need of reviewing applicant documents in person.",
                ],
              },
            },
          },
          total: "Total",
          free: "£ FREE",
          vat: "VAT",
          sendRequest: "Send Request",
          viewRentPassport: "View Rent Passport",
          requestRentPassport: "Request Rent Passport",
          successContent: {
            archived: {
              part1: "You are already connected to this Renter. They were in an",
              part2: "Archived",
              part3: "state. We have updated their state to be",
              part4: "Screening",
            },
            sent: {
              part1: "Great! We've sent an email request to",
              part2: "at",
            },
            title: "How do I speed up this reference?",
            body:
              "The fastest and most secure way to complete this reference is for applicants to connect via Open Banking. Give them a call to remind them!",
            moreInfo: "Find out more",
            leaflet: "Open Banking Leaflet",
            makeAnotherRequest: "Request Another Rent Passport",
            done: "Done",
          },
        },
        requestGuarantor: {
          title: "Request Guarantor",
          subTitle: "Inform this tenant that you require them to provide a guarantor.",
          screeningTitle: "Type of screening",
          fullScreening: "Comprehensive screening is required",
          free: "£ FREE",
          until: "Until",
          fullScreeningHelpText:
            "We recommend to only request a Comprehensive Screening if your tenant is ‘High Risk’, an ‘Accept’, or doesn’t meet the affordability of the property.",
          titleSent: "Request Sent",
          successSubTitle: "Great! We've sent a request to",
          successSubscription:
            "We'll let you know when they have a guarantor ready for you to review.",
          subjectToContract: "(subject to contract)",
          asideItem: {
            text: "Ask your tenant to provide a guarantor for their tenancy",
            title: "Guarantor",
          },
          total: "Total",
          guarantorScreening: "Guarantor Screening",
          plusEmployerLandlord: "+ Employer and Landlord Reference",
          screeningOptions: {
            instant: "Essential Screening",
            instantAndFull: "Essential + Comprehensive Screening",
            full: "Comprehensive Screening",
          },
          branchShares: "Branch shares",
          selectBranch: "Select branch",
          selectBranchHelpText: "Branch you would like to give access to this priority screening",
        },
        upgradeScreening: {
          variant: {
            pdf: {
              title: "Unlock PDF Report",
              preTitle:
                "Tick the box to confirm one screening you would like to unlock PDF report with.",
              btnText: "Unlock PDF",
              text:
                "PDF download is a part of Essential, Comprehensive and Priority screening, choosing a screening will include all the features of that screening",
            },
            default: {
              title: "Upgrade Screening",
              preTitle: "Tick the box to confirm one screening you would like to upgrade to.",
              btnText: "Upgrade",
              text:
                "Manual verification is a part of Essential, Comprehensive and Priority screening.",
            },
          },
          free: "£ FREE",
          vat: "VAT",
          screeningOptions: {
            creditCheck: "Credit Check",
            instant: "Essential Screening",
            comprehensive: "Comprehensive Screening",
            priority: "Priority Screening",
          },
        },
        cancelGuarantor: {
          title: "Cancel Guarantor Request",
          cancelMessage: "Are you sure you want to cancel the guarantor request for this Tenant?",
          titleCancel: "Guarantor Request Cancelled",
          successCancelMessage:
            "We've sent a notification that you no longer require a guarantor from",
        },
        requestEssentialScreening: {
          requestSuccessMessage: "Your request successfully sent",
          title: "Request Essential Screening",
          description:
            "Tick the box to confirm you would like to request this tenant completes essential screening.",
          label: "Essential Screening",
          free: "£ FREE",
          vat: "VAT",
          helpText:
            "Essential screening includes Pre-qualification plus unlock manual document verification and automated referee chasers.",
          chooseBranch: "Select branch",
          sendRequest: "Send Request",
          asideItem: {
            text: "Need to manually verify the reference?",
            title: "Essential Screening",
          },
          request: "Request",
        },
        requestCCOnly: {
          title: "Request CC Only Screening",
          ccOnlyTitle: "CC Only Screening",
          subTitle:
            "Tick the box to confirm you would like to request this tenant completes Credit Check screening.",
          free: "£ FREE",
          until: "Until",
          ccOnlyHelpText:
            "CC Only screeening includes Pre-qualification plus unlock credit check automated and manual verification",
          sendRequest: "Send Request",
          needCCOnly: "Need only renter Credit Check?",
          guaranteedRenter: "Guaranteed Renter",
          guaranteedRenterPlaceholder: "Choose guaranteed renter",
          requestSuccessMessage: "Your request successfully sent",
        },
        requestFullScreening: {
          title: "Request Comprehensive Screening",
          subTitle:
            "Tick the box to confirm you would like to request this tenant completes comprehensive screening.",
          fullScreening: "Comprehensive screening is required",
          guarantorSubTitle:
            "Tick the box to confirm you would like to request this guarantor completes comprehensive screening.",

          free: "£ FREE",
          until: "Until",
          fullScreeningHelpText:
            "Comprehensive screening will give you access to a renters full profile, including references.",
          guarantorFullScreeningHelpText:
            "Comprehensive screening will give you access to a guarantor’s full profile, including references.",
          branchTitle: "Branch shares",
          branchSubTitle: "Branch you would like to give access to this comprehensive screening",
          sendRequest: "Send Request",
          chooseBranch: "Select branch",
          needRef: "Need an employment and landlord reference?",
          guaranteedRenter: "Guaranteed Renter",
          guaranteedRenterPlaceholder: "Choose guaranteed renter",
        },
        requestPriorityScreening: {
          title: "Request Priority Screening",
          subTitle:
            "Tick the box to confirm you would like to request this tenant complete priority screening",
          priorityScreening: "Priority Screening",
          priorityScreeningHelpText:
            "Priority screening includes comprehensive screening plus unlock support from a dedicated case handler to encourage renters to complete their screening process promptly.",
          branchTitle: "Branch shares",
          branchSubTitle: "Branch you would link to give access to this priority screening",
          chooseBranch: "Select branch",
          sendRequest: "Send Request",
          requestSuccessMessage: "Your request successfully sent",
          asideItem: {
            text: "Need to prioritize this screening?",
            title: "Priority screening",
          },
        },
        requestRightToRent: {
          requestSuccessMessage: "Your request successfully sent",
          title: "Request Right to Rent",
          description:
            "Tick the box to confirm you would like to request this tenant provides a Right to Rent check through the Canopy app.",
          checkboxGroup: {
            rightToRent: "Right To Rent",
            rightToRentHintText:
              "This service guides applicants through the documents required to evidence their right to rent. You will have access to these documents and we’ll guide you through the process required from you by law.",
            hintFooterText: "For more information, please visit:",
            hintLink: "Help Centre",
            rightToRentHintNoteText:
              "Note: The ID Verification service is a pre-requisite for Canopy’s Right to Rent checks.",
            subjectToContract: "(subject to contract)",
            idVerification: "ID Verification",
            idVerificationHintText:
              "Add an extra layer of security to ensure your applicant is who they say they are. Our IDV service requires users to verify their photo ID through IDNow and undergo liveness video detection to detect cases of fraud.",
            vat: "VAT",
            free: "£ FREE",
          },
          idVerificationText: "ID Verification is included as a part of Right to Rent check.",
          chooseBranch: "Select branch",
          vat: "VAT",
          free: "£ FREE",
          total: "Total",
          sendRequest: "Send Request",
          request: "Request",
          asideItem: {
            text: "Ask your tenant to provide proof that they can live in the UK",
            title: "Right To Rent",
          },
        },
        readyToCompleteRightToRent: {
          readyToComplete: "Ready To Complete",
          title: "Complete Right to Rent",
          titleV2: "Right to Rent",
          obtain: "Obtain",
          docText: "Type of identification documents provided",
          check: "Check",
          uploadedDocs: "Uploaded documents",
          uploadMore: "Upload more documents",
          uploadedYou: "Uploaded by you",
          store: "Store",
          text:
            "You must delete a tenant's right to rent one year after the tenancy has ended. We'll remind you when it does.",
          endDateLabel: "End of tenancy date",
          confirmSeenDocuments:
            "I confirm, I have seen and checked the renter’s identity documents in person and they comply to the UK Government’s current Right to Rent regulations.",
          completeRightToRent: "Complete Right to Rent",
          requestSuccessMessage: "Your request successfully sent",
          requestEditSuccessMessage: "Right to Rent request successfully edited",
          asideItem: {
            text: "Ask your tenant to provide proof that they can live in the UK",
            title: "Complete Right to Rent",
          },
          manual: {
            learnMore: "Learn more",
            documentTypeLabel: "Type of identification documents provided",
            documentTypePlaceholder: "Document type here",
            seenDocumentLabel:
              "I have seen the identification document('s) that allows them to live in the UK",
            checkLabelDocument:
              "I have checked that the provided documents were valid, with the tenant present",
            checkLabelShareCode:
              "I confirm, I have checked the share code provided and confirm that the prospective tenant is the subject of the photo displayed on the Home Office online service.",
            unsure: "Unsure? ",
            clickHere: "Click here",
            toLearnMore: " to learn more",
            ukGovShareCode: "UK Gov Share Code",
            uploadDocOpt: "Upload documents ",
            opt: "(optional)",
            text:
              "It is the law to keep hold of your tenant's proof of right to rent up until a year after their tenancy ends.",
            storeIt: "Store it on Canopy for quick access:",
            uploadMore: "Upload more documents",
            description:
              "You must delete a tenant's right to rent one year after the tenancy has ended. We'll remind you when it does.",
            btnText: "Submit Right to Rent",
            selectValues: {
              expiredPassport: "UK / IE Expired Passport",
              immigrationDoc: "UK immigration document",
              intPassport: "International Passport",
              other: "Other",
              passport: "UK / EU Passport",
              residenceCard: "UK Residence Card / Others",
              ukPassport: "UK / EU Passport", // for backwards compatibility
            },
          },
        },
        cancelRightToRent: {
          title: "Cancel Right To Rent",
          cancelMessage:
            "Are you sure you want to cancel this Right To Rent check? If you are sure then click the confirm cancellation button below",
          titleCancel: "Right To Rent Request Cancelled",
          successCancelMessage: "Right to Rent request successfully cancelled",
        },
        requestIdVerification: {
          requestSuccessMessage: "Your request successfully sent",
          title: "Request ID Verification",
          description:
            "Tick the box to confirm you would like to request this tenant provides a ID verification through the Canopy app.",
          checkboxGroup: {
            rightToRent: "Right To Rent",
            rightToRentHintText:
              "This service guides applicants through the documents required to evidence their right to rent. You will have access to these documents and we’ll guide you through the process required from you by law.",
            hintFooterText: "For more information, please visit:",
            hintLink: "Help Centre",
            rightToRentHintNoteText:
              "Note: The ID Verification service is a pre-requisite for Canopy’s Right to Rent checks.",
            subjectToContract: "(subject to contract)",
            idVerification: "ID Verification",
            idVerificationHintText:
              "Add an extra layer of security to ensure your applicant is who they say they are. Our IDV service requires users to verify their photo ID through IDNow and undergo liveness video detection to detect cases of fraud.",
            vat: "VAT",
            free: "£ FREE",
          },
          chooseBranch: "Select branch",
          free: "£ FREE",
          vat: "VAT",
          total: "Total",
          sendRequest: "Send Request",
          request: "Request",
          asideItem: {
            text: "Ask your tenant to provide proof of their identity",
            title: "ID Verification",
          },
        },
        cancelIdVerification: {
          title: "Cancel ID Verification",
          cancelMessage:
            "Are you sure you want to cancel this Id Verification check? If you are sure then click the confirm cancellation button below",
          titleCancel: "Right To Rent Request Cancelled",
          successCancelMessage: "Right to Rent request successfully cancelled",
        },
        shareBranch: {
          selectBranch: "Select branch",
          linkInvite: "Link Invite",
          copy: "Copy",
          inviteByEmail: "Invite by email",
        },
        markReferenceAsComplete: {
          title: "Complete Reference",
          proceedingQuestion: {
            title: "Has the renter been approved to move into the property?",
            yes: "Yes",
            no: "No",
          },
          billsIncludedQuestion: {
            title: "Are bills included?",
            yes: "Yes",
            no: "No",
          },
          moveInDetails: {
            title: "Confirm move in details",
            moveInDateLabel: "Move in date",
            moveInDatePlaceholder: "DD / MM / YYYY",
            propertyAddressLabel: "Property address",
            propertyAddressPlaceholder: "Address Lookup",
          },
          saveButton: "Save",
          markAsCompleteButton: "Mark as complete",
        },
      },
      list: {
        columnLabels: {
          name: "Name",
          status: "Passport Status",
          email: "Email",
          branches: "Branches",
          screening: "Screening",
          affordability: "Affordability",
          reminders: "Reminders Sent",
          dateInvited: "Date Invited",
          dateConnected: "Date Connected",
          numberOfReminders: "Reminders",
          tenancyStatus: "Tenant Status",
          screeningType: "Type",
          phoneNumber: "Phone Number",
          source: "Source",
        },
        notAvailableInAllBranches: {
          selectABranch: "Select a branch",
          messagePart1: "To view Renters based on their",
          tenantStatus: "Tenant status",
          preQualifications: "Pre-Qualifications",
          messagePart2:
            "you need to select a branch. Or you can view all renters that you are connected to by viewing the",
          allConnected: "All Connected",
          messagePart3: "tab",
          viewAllConnected: "View All Connected",
        },
        preQualificationsRedirectContent: {
          link: "All Connected",
          textPart1:
            "To view Renters based on their Pre-Qualifications you need to select a branch. Or you can view all renters that you are connected to by viewing the",
          textPart2: "tab",
          title: "Select a branch",
          btnText: "View All Connected",
        },
        dialog: {
          title: "Are you sure to send reminder?",
          text: "Please confirm you want to resend the invite to",
          reminderSent: "Your reminder was successfully sent",
          tenancyStatusChanged: "Tenant status successfully changed",
        },
        cancelInviteDialog: {
          title: "Are you sure to cancel invite?",
          text: "Please confirm you want to cancel the invite to",
          cancelledInvite: "Invite was successfully cancelled",
        },
        fallbackContent: {
          title: "You have no connected Rent Passports",
          subTitle:
            "Request Rent Passports from your prospective tenants to see their screening results.",
          btnText: "Request Rent Passport",
        },
        invitedFallback: {
          title: "You have no Rent Passport invitations pending",
          subTitle:
            "Request Rent Passports from your prospective tenants to see their screening results.",
          btnText: "Request Rent Passport",
        },
        activeFallback: {
          title: "You have no active Rent Passports",
          subTitle:
            "Request Rent Passports from your prospective tenants to see their screening results.",
          btnText: "Request Rent Passport",
        },
        notActiveFallback: {
          title: "You have no connected Rent Passports set to Not Active",
          subTitle:
            "Go to the Active tab to view Rent Passports that you wish to change to Not Active",
        },
        preQualificationsFallback: {
          title: "You have no connected Pre-Qualification Rent Passports",
          subTitle:
            "Request Rent Passports from your prospective tenants to see their screening results.",
          btnText: "Request Rent Passport",
        },
      },
      filter: {
        screeningType: {
          RENTER: "Renter",
          GUARANTOR: "Guarantor",
        },
        screening: {
          PRE_QUALIFICATION: "Pre-qual",
          CC_ONLY: "Credit Check",
          INSTANT: "Essential",
          FULL: "Comprehensive",
          PRIORITY: "Priority",
        },
        tenancyStatus: {
          SCREENING: "Screening",
          IN_TENANCY: "Progressing",
          ARCHIVED: "Not Progressing",
        },
        source: {
          MANUAL_RENTER: "Renter Invite",
          HQ_INVITE_FILTER: "HQ Invite",
          BRANCH_DEEPLINK: "Link Invite",
          RENT_READY_API: "OnTheMarket",
          REFERENCE_API: "API",
        },
        labels: {
          screeningType: "Type",
          screening: "Screening",
          source: "Source",
          tenancyStatus: "Tenant Status",
          affordability: "Affordability",
          status: "Passport Status",
          reminders: "Reminders",
          dateInvited: "Date Invited",
          dateConnected: "Date Connected",
        },
      },
      detailsPage: {
        generateReport: "Generating report. Please don’t refresh the page, this may take a minute",
        incomeTitle: "Income",
        savingTitle: "Savings",
        progress: {
          title: "Renter Progress",
          rent: "Rent",
          step: "Step",
          pcw: "pcw",
          progressing: "Progressing",
          property: "Property",
          step1Text: "Request Sent",
          step2Text: "Referencing",
          step3Text: "Referencing Results",
          step4Text: "Reference Finalized",
          btnText: "Is this renter moving in?",
          step1Info:
            "Reference process is initiated by adding, inviting, or sending connection requests to renters. Awaiting renters to start the screening process.",
          step2Info:
            "Renters/Guarantors starts their referencing process, for this step to complete all added they must finish mandatory section on their RentPassport.",
          step3Info:
            "Renters/Guarantors successfully finished their renter passport and the referencing results are generated.",
          step4Info: "The RentPassport is manually marked as finalized.",
        },
        favourite: "Favourite",
        favourited: "Favourited",
        passportDetails: "Passport details",
        otherDetails: "Other details",
        rentAffordability: "Rent Affordability",
        guarantorAffordability: "Guarantor Affordability",
        fullScreening: "Comprehensive Screening",
        or: "or",
        thisPerson: "This Person is a",
        guarantorTitle: "Guarantor",
        renting: "Renting",
        markAsComplete: "Mark as complete",
        tenantStatus: "Tenant Status",
        creditCheckConsiderBlockWarnings: {
          ukResident: "UK Resident",
          creditCheck: "Credit Check",
          addressHistory: "Address History",
          creditIndicator: "Credit Indicator",
          financialHistory: "Financial History",
          unableToVerify: "Unable to verify",
          zeroUkAddressesVerified: "0 UK addresses verified",
          notFound: "Not Found",
          notUKResident: "Not UK Resident",
          under18: "Under 18",
          veryPoor: "Very Poor",
          iva: "IVA",
          bankruptcy: "Bankruptcy",
          ivaAndBankruptcy: "IVA and Bankruptcy",
          present: "Present",
          presentOver300: "Present - Over £300",
        },
        creditCheckWarnings: {
          identity: {
            notLiveInUK:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            notUKRes:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            defaultError: "We were unable to locate a credit file for this person.",
            defaultRejected: "We were unable to locate a credit file for this person.",
            ruleIau18: "Renter under 18 years of age at time of reference",
          },
          addressHistory: {
            notLiveInUK:
              "It appears that no credit file exists for this person so we were unable to verify any of this person's addresses.",
            notUKRes:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to verify any of this person’s addresses.",
            defaultError:
              "We were unable to locate a credit file, so cannot verify any of the submitted addresses.",
            defaultRejected:
              "We were unable to locate a credit file, so cannot verify any of the submitted addresses.",
          },
          creditIndicator: {
            notLiveInUK:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            notUKRes:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            defaultError: "We were unable to locate a credit file for this person.",
            defaultRejected: "We were unable to locate a credit file for this person.",
            ruleVpoor:
              "This person's credit rating is Very Poor - however they have no adverse credit",
          },
          financialSummary: {
            notLiveInUK:
              "It appears that no credit file exists for this person so we were unable to verify this person's credit history.",
            notUKRes:
              "It appears that no credit file exists for this person so we were unable to verify this person's credit history.",
            defaultError: "We were unable to locate a credit file for this person.",
            defaultRejected: "We were unable to locate a credit file for this person.",
            defaultIva: "This person's financial history is indicative of a high-risk tenant",
            defaultBnkr: "This person's financial history is indicative of a high-risk tenant",
            defaultIvaabnkr: "This person's financial history is indicative of a high-risk tenant",
            defaultRuleHaccj: "This person's financial history is indicative of a high-risk tenant",
            letmojoRuleH2accj:
              "This person's financial history is indicative of a high-risk tenant",
            letmojoRuleHbigaccj:
              "This person's financial history is indicative of a high-risk tenant",
            letmojoRuleHlowaccj:
              "This person has adverse credit you may want to review and consider.",
            defaultRuleHbigsccj:
              "This person's financial history is indicative of a high-risk tenant",
            defaultRuleHlowsccj:
              "This person has adverse credit you may want to review and consider.", // TODO: update message
            letmojoRuleHsccj: "This person has adverse credit you may want to review and consider.", // TODO: update message
          },
          activeCCJ: "Active CCJs",
          satisfiedCCJ: "Satisfied CCJs",
          insolvency: "Insolvency",
        },
        addOnService: {
          title: "Add On Services",
        },
        upgradeScreening: {
          title: "Upgrade Screening",
        },
        summaryNotes: {
          title: "Referencing Team Summary",
        },
        agentInviteNoAccess: {
          title: "You don’t have access to this users RentPassport",
          btnText: "Request RentPassport",
          btnSuccessText: "Request Sent",
          text:
            "Clicking the button below will send an email to the applicant requesting access to their RentPassport. You will be notified if the request is accepted.",
        },
        connectedBranches: {
          connectionStates: {
            PRE_QUALIFICATION_SCREENING: "Pre-qualification Screening",
            INSTANT_SCREENING: "Essential Screening",
            FULL_SCREENING: "Comprehensive Screening",
            PRIORITY_SCREENING: "Priority Screening",
            PRE_QUALIFICATION: "Pre-qualification Screening", // NEW REF SERVICE
            INSTANT: "Essential Screening", // NEW REF SERVICE
            FULL: "Comprehensive Screening", // NEW REF SERVICE
            PRIORITY: "Priority Screening", // NEW REF SERVICE
            INVITED: "Invited, Not Connected",
            REJECTED: "Rejected, Not Connected",
            CC_ONLY: "Credit Check",
          },
          rightToRent: "Right To Rent",
          title: "Connected Branches",
        },
        branchNotification: {
          allBranchMode: {
            oneBranch: {
              title: "Select the branch to see the full result",
              link: "Click here to switch you into the connected branch.",
              text: "Or choose from the list to the right, or the selector at the top of the page.",
            },
            manyBranches: {
              title: "Select a branch to see the full result",
              subTitle:
                "To switch to a connected branch, click on the branch in the list to the right, or in the selector above.",
            },
          },
          invited: {
            title: "This user has been invited to",
            subTitle: "Please change the your branch view or resend the invite",
            cancelInvite: "Cancel Invite",
            resendInvite: "Resend Invite",
          },
          notInvited: {
            title: "This user is not connected to",
            subTitle:
              "Please switch to a different branch from the list on the right or invite them",
            btnText: "Invite To Connect",
          },
        },
        properties: {
          properties: "Properties",
          notAddedToProperty: "Renter is not added to any property",
          add: "Add",
          delete: "Delete",
          targetRent: "Target rent",
          addPropertyDialog: {
            addProperty: "Add Property",
            property: "Property",
            placeholder: "Search for property with address or full postcode",
            message:
              "Type the full UK Postcode. If you are having trouble locating address try adding a space.",
            rent: "Rent",
            add: "Add",
          },
        },
        tenancies: {
          jointScreenings: "Households",
          tenancies: "Tenancies",
          tenants: "Tenants",
          rentShare: "Rent share",
          delete: "Delete",
        },
        adminNotes: {
          GLOBAL: {
            EXTERNAL: {
              itemTitle: "External summary",
              itemTitleAdded: "Admin summary",
              itemFooter:
                "all agents attached to this Rent Passport will be able to see what you write in here.",
            },
          },
          DEFAULT: {
            EXTERNAL: {
              itemTitle: "External note",
              itemTitleAdded: "Admin note",
              itemFooter:
                "all agents attached to this Rent Passport will be able to see what you write in here.",
            },
          },
        },
        idVerification: {
          title: "ID Verification",
          description: "Ask your tenant to provide proof of their Identity",
          idRenter: "ID renter submitted",
          reason: "Reason",
          verifiedDate: "Verified",
          downloadAll: "Download All",
          documentTypes: {
            IDFRONTSIDE: "ID Frontside",
            IDFRONTSIDEORIGINAL: "ID Frontside",
            IDBACKSIDE: "ID Backside",
            IDBACKSIDEORIGINAL: "ID Backside",
            LIVENESSLEFT: "Face left side",
            LIVENESSRIGHT: "Face right side",
            SECURITY1: "Document security feature",
            SECURITY2: "Document security feature",
            SECURITY3: "Document security feature",
            SECURITY4: "Document security feature",
          },
          message: {
            NOT_STARTED: "This person has not started their ID verification.",
            IN_PROGRESS: "This person has started their ID verification.",
            VERIFICATION: "This person has started their ID verification.",
            NO_PHOTO_ID: "This person is not able to provide a Photo ID",
            BLOCKED:
              "Our identity provider, IDnow, is unable to confirm the validity of these documents.",
          },
          buttonConfig: {
            NO: {
              text: "Request",
            },
            NOT_STARTED: {
              text: "Cancel",
            },
            BLOCKED: {
              text: "Contact Support",
            },
            ERROR: {
              text: "Contact Support",
            },
          },
        },
        creditCheck: {
          title: "Credit Check",
          message: {
            NO: "This person has not started this section yet.",
            DRAFT: "This user has not finished this section yet.",
            EXPIRED: "This user has not finished this section yet.",
            MANUAL_REVIEW: "This user has not finished this section yet.",
            NOT_LIVED_IN_UK:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            ERROR: "We were unable to locate a credit file for this person.",
            AUTO_REJECTED: "We were unable to locate a credit file for this person.",
            REJECTED: "We were unable to locate a credit file for this person.",
          },
          warning: {
            AGE_UNDER_18: "Renter under 18 years of age at time of reference.",
          },
        },
        addressHistory: {
          title: "Address History",
          message: {
            NO: "This person has not added any addresses.",
            DRAFT: "This user has not added any addresses",
            EXPIRED: "This user has not added any addresses",
            MANUAL_REVIEW: "This user has not added any addresses",
            NOT_LIVED_IN_UK:
              "It appears that no credit file exists for this person so we were unable to verify any of this person’s addresses.",
            ERROR:
              "We were unable to locate a credit file, so cannot verify any of the submitted addresses.",
            AUTO_REJECTED:
              "We were unable to locate a credit file, so cannot verify any of the submitted addresses.",
            NOT_UK_RESIDENT:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to verify any of this person’s addresses.",
          },
          messageWhenUserHasAddress: {
            EXPIRED: "This user has not yet completed a credit check to verify their addresses",
            DRAFT: "This user has not yet completed a credit check to verify their addresses",
            MANUAL_REVIEW:
              "This user has not yet completed a credit check to verify their addresses",
            PRE_QUALIFICATION: {
              EXPIRED:
                "This user has provided their credit check details. We will verify their details once the Rent Passport has been upgraded.",
              DRAFT:
                "This user has provided their credit check details. We will verify their details once the Rent Passport has been upgraded.",
              MANUAL_REVIEW:
                "This user has provided their credit check details. We will verify their details once the Rent Passport has been upgraded.",
            },
          },
        },
        creditIndicator: {
          title: "Credit Indicator",
          message: {
            NO: "This person has not started their credit check yet.",
            DRAFT: "This user has not started their credit check yet.",
            EXPIRED: "This user has not started their credit check yet.",
            NOT_LIVED_IN_UK:
              "This person has not lived in the UK for a period of at least 12 months so we were unable to locate a credit file for this person.",
            ERROR: "We were unable to locate a credit file for this person.",
            AUTO_REJECTED: "We were unable to locate a credit file for this person.",
            VERY_POOR: "This person's credit rating is indicative of a high-risk tenant.",
            VERY_POOR_WARNING:
              "This person’s credit rating is Very Poor - however they have no adverse credit.",
          },
          dateCheckedLabel: "Date Checked",
        },
        financialSummary: {
          title: "Financial Summary",
          message: {
            NO: "This person has not started their credit check yet.",
            DRAFT: "This user has not started their credit check yet.",
            EXPIRED: "This user has not started their credit check yet.",
            MANUAL_REVIEW: "This user has not started their credit check yet.",
            ACTIVE_CCJS_WARNING:
              "This person has adverse credit you may want to review and consider.",
            NOT_LIVED_IN_UK:
              "It appears that no credit file exists for this person so we were unable to verify this person’s credit history.",
            ERROR: "We were unable to locate a credit file for this person.",
            AUTO_REJECTED: "We were unable to locate a credit file for this person.",
            WARNING: "This person's financial history is indicative of a high-risk tenant.",
            PRE_QUALIFICATION: {
              DRAFT: "Please upgrade the Rent Passport to view the user’s financial summary.",
              EXPIRED: "Please upgrade the Rent Passport to view the user’s financial summary.",
              MANUAL_REVIEW:
                "Please upgrade the Rent Passport to view the user’s financial summary.",
            },
          },
        },
        income: {
          title: "Income",
          totalIncome: "Gross annual income",
          manualUpload: "Manual Upload",
          document: "Document",
          documents: "Documents",
          transaction: "Transaction",
          transactions: "Transactions",
          openBanking: "Open Banking",
          employmentStatus: "Employment Status *",
          startDate: "Start Date *",
          grantStartDate: "Grant / Bursary Start Date *",
          basicalSalary: "Basic Salary *",
          grantAmount: "Loan / Grant Amount",
          loan: "Maintenance Loan",
          loanStartDate: "Loan Start Date",
          course: "Course",
          qualification: "Qualification",
          courseStart: "Course Start",
          courseEnd: "Course End",
          grantsOrBursaries: "Grants / Bursaries",
          yes: "Yes",
          no: "No",
          helpInfo:
            "* Only income transactions are verified. For verified employment details please check the employment reference section.",
          message: {
            contractWarning: "This income is a Temporary - Under 6 Month.",
            IN_PROGRESS:
              "This person has uploaded proof of their income. Our referencing team is in the progress of verifying the transactions.",
            SUBMITTED:
              "This person has self certified their income. Upgrade your RP to get this income verified.",
            LOW_MONTH_EVIDENCE:
              "This person is self-employed, but cannot provide over six months of evidence.",
            NO_INCOME: "This person has stated that they have no income sources.",
            noPayments: "This person has not provided any payment information yet.",
            potentialContractIssue: "This income is a Temporary - 6-12 Month.",
            studentNoIncome: "This person is a student without any income.",
            UNABLE_TO_VERIFY: "We were unable to verify this users income information is correct",
            ZERO_HOUR_CONTRACT: "There is an income item with Zero Hour Contract Employment Basis.",
            PRE_QUALIFICATION: {
              SUBMITTED:
                "This person has provided their income details. Our referencing team will review this when the Rent Passport has been upgraded.",
              IN_PROGRESS:
                "This person has provided their income details. Our referencing team will review this when the Rent Passport has been upgraded.",
              VERIFICATION:
                "This person has provided their income details. Our referencing team will review this when the Rent Passport has been upgraded.",
              DRAFT:
                "This person has provided their income details. Our referencing team will review this when the Rent Passport has been upgraded.",
              FEEDBACK_REQUESTED:
                "This person has provided their income details. Our referencing team will review this when the Rent Passport has been upgraded.",
            },
          },
          headerDescription: {
            studentNoIncome: "No grants or loans",
          },
        },
        rent: {
          totalRent: "Total Rent",
          title: "Rent Payments",
          monthlyRent: "Monthly Rent",
          manualUpload: "Manual Upload",
          document: "Document",
          documents: "Documents",
          notVerified: "Not Verified",
          referToLandlordRefer: "Refer to Landlord Reference",
          requestLR: "Request Landlord Reference",
          selfCertified: "Self Certified",
          transaction: "Transaction",
          viewRentDetails: "View Rent Details",
          transactions: "Transactions",
          openBanking: "Open Banking",
          annualRent: "Annual Rent",
          helpInfo:
            "* Only rent transactions are verified. For verified tenancy information please check the landlord reference section.",
          message: {
            UNABLE_TO_VERIFY:
              "This person has uploaded proof of their rent payments. The documents did not meet our verification requirements and therefore we were unable to verify them.",
            NOT_RENT: "This person has not rented in the UK in the last 12 months",
            HOMEOWNER: "This person has stated that they are a homeowner",
            IN_PROGRESS:
              "This person has uploaded proof of their rent. Our referencing team is in the process of verifying the transactions.",
            noPayments: "This person has not completed any rental payments yet.",
            notStarted: "This person has not started this section yet.",
            notHomeowner:
              "This guarantor is not a homeowner. Before proceeding with this guarantor, you may wish to consider whether your company requires a guarantor to be a homeowner or not.",
            homeownerNotRented:
              "This person has self-asserted that they are a homeowner, that has not rented in the UK in the last 12 months",
            homeownerRented:
              "This person has self-asserted that they are a homeowner. This has not been verified.",
            selfCertified:
              "The renter has stated their rent amount, but has not verified this using Open Banking.",
            selfCertifiedNoFullScreeningAccess:
              "For further verification, please ask the renter to supply Open Banking evidence or request a Landlord Reference.",
          },
        },
        rightToRent: {
          title: "Right to Rent",
          docDescription:
            "Depending where your tenant is from will depend which type of documentation you will require.",
          description:
            "Right to rent is a Government policy contained in the Immigration Bill 2015 whereby landlords have to check the immigration status of tenants they rent properties to.",
          learnMoreDoc: "Learn more about documentation",
          idRenter: "ID renter submitted",
          ukGovShareCode: "UK Gov Share Code",
          idRenterManual: "ID Renter Will Provided In Person",
          passport: "Passport",
          endOfTenancy: "End of Tenancy",
          verifiedBy: "Verified by",
          messages: {
            ACCEPTED: "The person has started their right to rent check",
            PENDING: "The person has not started their right to rent check",
          },
          blockedMessage:
            "Our identity provider, IDnow, is unable to confirm the validity of these documents.",
          buttonConfig: {
            NO: {
              text: "Request Right To Rent",
            },
            PENDING: {
              text: "Cancel",
            },
            CANCELLED: {
              text: "Request Right To Rent",
            },
            BLOCKED: {
              text: "Contact Support",
            },
            READY_TO_COMPLETE: {
              text: "Ready To Complete",
            },
            DECLINED: {
              text: "Request Right To Rent",
            },
            COMPLETED: {
              text: "Edit",
              textV2: "Set tenancy end date",
            },
          },
        },
        saving: {
          title: "Savings",
          totalRent: "Total",
          openBanking: "Open Banking",
          manualUpload: "Manual Upload",
          manualVerified: "Manual Verified",
          autoVerified: "Auto Verified",
          viewSaving: "View Saving",
          note: {
            autoVerified: "Auto Verified",
            manualVerified: "Manual Verified",
          },
          message: {
            PENDING:
              "This person has uploaded proof of their savings. Our referencing team is in the process of verifying the transactions.",
            DECLINED:
              "This person has uploaded proof of their savings. The documents did not meet our verification requirements and therefore we were unable to verify them.",
          },
        },
        employmentReference: {
          title: "Employment Reference",
          message: {
            noEmplRef: "This person has not provided an employment reference.",
          },
          requestSent: "Request sent to Employer",
          received: "Reference received from Employer",
          verification: "Verification",
        },
        landlordReference: {
          title: "Landlord Reference",
          message: {
            noLandlordRef: "This person has not provided a landlord reference.",
            notRent: "This person has not rented in the UK in the last 12 months.",
            homeowner: "This person has stated that they are a homeowner",
            homeownerGuarantor: "This guarantor has stated they are homeowner.",
            notHomeowner:
              "This guarantor is not a homeowner. Before proceeding with this guarantor, you may wish to consider whether your company requires a guarantor to be a homeowner or not.",
          },
          requestSent: "Request sent to Landlord",
          received: "Reference received from Landlord",
          verification: "Verification",
        },
        guarantorSection: {
          title: "Guarantors",
          types: {
            PERSON: {
              title: "Guarantor",
              dateText: "Invite sent",
              affordabilityText: "Affordability",
            },
            HOUSING_HAND: {
              title: "Guarantor Replacement",
              dateText: "Applied on",
              description: "RentGuarantor",
            },
            default: {
              title: "Guarantor",
              dateText: "Invite sent",
              affordabilityText: "Affordability",
            },
          },
        },
        followUps: {
          automaticSent: "Automated email follow up sent",
          manualSent: "Reference Team follow up",
          title: "Follow Ups",
        },
        jointScreeningSection: {
          titleJointScreening: "Household Screenings",
          created: "Created",
          share: "Rent Share",
          tenants: "No. of Tenants",
        },
        rentTracking: "Rent Tracking",
        rentPayments: "Rent Payments",
        viewReference: "View Reference",
        adminUser: "Admin User",
        dateAccepted: "Accepted",
        dateRejected: "Rejected",
        dateSubmitted: "Submitted",
        alreadyHas: "This user already has",
        guarantor: "guarantor",
        guarantors: "guarantors",
        guaranteedRenters: "guaranteed renters",
        pageNumber: "Page _",
        userIsGuarantor: "This user is a Guarantor",
        requestDisabled: "Requesting a guarantor for this user is disabled",
        guaranteedRenter: "guaranteed renter",
      },
      tab: {
        connected: "All Connected",
        invited: "Invited",
        favourites: "Favourites",
        active: "Screening",
        notActive: "Completed",
        preQual: "Pre-qualification",
      },
      references: {
        landlord: {
          startDate: "Tenancy Start Date",
          endDate: "Tenancy End Date",
          isNoticed: "Notice Served",
          isAnyRentArrears: "Rent Arrears",
          isInventoryComplete: "Property Damage",
          isRenterSatisfying: "Would Rent To Tenant Again?",
          address: "Address",
          monthlyRentAmount: "Monthly Rent",
          labels: {
            landlordName: "Landlord Name",
            damageDescription: "Damage Description",
            arrearsDescription: "Arrears Description",
            monthlyRentAmount: "Rent Amount",
            paymentFrequency: "Frequency",
            startDate: "Tenancy Start Date",
            address: "Address",
            fullName: "Name",
            email: "Email",
            phoneNumber: "Phone",
            isApproved: "Did you rent to this person?",
            rentAmount: "Monthly Rent",
            endDate: "Tenancy End Date",
            isNoticed: "Notice Served",
            isAnyRentArrears: "Rent Arrears",
            isPropertyDamaged: "Property Damage",
            isInventoryComplete: "Inventory Complete",
            isRenterSatisfying: "Rent to Tenant Again?",
            additionalInfo: "Additional information",
          },
          messages: {
            PENDING: {
              REFEREE:
                "This person has sent a reference request to their landlord. We are waiting for the landlord to accept the reference request.",
              ACCEPT:
                "This person’s landlord has accepted the reference invite. We are waiting for the landlord to complete the reference.",
              VERIFICATION:
                "This person’s landlord has completed the reference. Our referencing team is in the process of verifying the reference.",
            },
            ACCEPTED_WITH_WARNINGS:
              "We were able to verify this landlord reference. The landlord has stated that they would not rent to this person again.",
            ACCEPTED_CONSIDER_WITH_WARNINGS:
              "The reference contains negative feedback about this person as a renter",
            DECLINED: {
              REFEREE:
                "This person has sent a reference request to their landlord. The reference request was rejected by the landlord.",
              VERIFICATION:
                "The landlord has uploaded a reference for this person. The reference did not meet our verification requirements and therefore we were unable to verify it.",
            },
          },
          warning: "Warning",
          warnings: "Warnings",
        },
        employer: {
          employmentType: "Employment Type",
          startDate: "Start Date",
          annualSalary: "Annual Income",
          jobTitle: "Job Title",
          paymentFrequency: "Payment Frequency",
          companyName: "Company name",
          employmentStatus: "Employment Status",
          labels: {
            companyName: "Company Name",
            employmentStatus: "Employment Status",
            employmentType: "Employment Type",
            annualSalary: "Annual Income",
            jobTitle: "Job Title",
            refereeName: "Referee Name",
            annualIncome: "Annual Income",
            paymentFrequency: "Payment Frequency",
            startDate: "Start Date",
            fullName: "Name",
            email: "Email",
            phoneNumber: "Phone",
            isApproved: "Does this person work for you?",
            reason: "Reason",
            message: "Message",
            additionalInfo: "Additional information",
          },
          messages: {
            PENDING: {
              REFEREE:
                "This person has sent a reference request to their employer. We are waiting for the employer to accept the reference request.",
              ACCEPT:
                "This person’s employer has accepted the reference request. We are waiting for the employer to complete the reference.",
              VERIFICATION:
                "This person’s employer has completed the reference. Our referencing team is in the process of verifying the reference.",
            },
            DECLINED: {
              REFEREE: "This person is employed, but unable to provide a reference.",
              VERIFICATION: "This person is employed, but unable to provide a reference.",
            },
            ACCEPTED_WITH_WARNINGS:
              "The employer has uploaded a reference for this person. The reference contained negative feedback about this person.",
            CAN_NOT_PROVIDE: "This person is not employed, so is unable to provide a reference.",
            SELF_EMPLOYED:
              "This person is self-employed and is therefore unable to provide an employment reference.",
            STUDENT:
              "This person is a student and is therefore unable to provide an employment reference.",
            RETIRED:
              "This person is retired and is therefore unable to provide an employment reference.",
            UNEMPLOYED:
              "This person is unemployed and is therefore unable to provide an employment reference.",
            ACCEPTED_BUT_CONTACT_TYPE:
              "The employer has uploaded a reference for this person, which has been verified. However they are on a temporary contract.",
            ZERO_HOUR_CONTRACT:
              "There is an employment reference item with Zero Hour Contract Employment Basis.",
            NO: "This person has not provided an employment reference.",
            POTENTIAL_ISSUE_CONTRACT: "This employment reference is a Temporary - 6-12 Month.",
            WARNING_CONTRACT: "This employment reference is a Temporary - Under 6 Month.",
          },
        },
        common: {
          additionalInfo: "Additional Information",
          reason: "Reason",
        },
        rejectionTypes: {
          UNKNOWN_PERSON: "I don't know this person",
          NEVER_RENTED: "I have never rented a property to this person",
          HR_TEAM_REFERENCE: "Our HR team does all our referencing",
          OTHER: "Other",
        },
      },
    },
    publicRentPassport: {
      auth: {
        title: "Rent Passport",
        subtext: "Please enter renters email address to access their RentPassport",
        form: {
          emailTitle: "Email Address",
          emailPlaceholder: "Enter renters email here",
          actionButtonText: "Authenticate",
        },
      },
      passport: {
        rentalPreferences: {
          title: "Rental Preferences",
        },
        affordability: {
          title: "Rent Affordability",
          perMonth: "pcm",
        },
        rentReady: "RentReady",
        creditCheck: {
          title: "Credit Check",
          address: "Address",
          submitted: "Submitted",
          adverseCredit: "Adverse Credit",
          details: "Details",
        },
        income: {
          title: "Income",
        },
        status: {
          disabled: {
            title: "You don't have access to this applicant's RentPassport yet",
            text:
              "This page will be updated once they do. Please check back later or Create an account to unlock the full RentPassport",
          },
          noAccount: {
            title: "The applicant hasn't yet created an account with Canopy",
            text:
              "This page will be updated once they do. Please check back later or Create an account to unlock the full RentPassport",
          },
          notComplete: {
            title: "The applicant hasn't finished their RentPassport",
            text:
              "This page will be updated once they do, Please check back later or request a reference",
          },
        },
        disclaimer: {
          start: "The information on this report is self-certified by the renter.",
          linkText: "Upgrade",
          end: " the renter for a verified reference.",
        },
      },
      agentUpsell: {
        title: "Create a Canopy HQ Account to unlock this renters full reference",
        subTitle:
          "No platform fees or monthly subscription, pay per reference and you can cancel at any time.",
        essential: {
          title: "Essential",
          features: [
            "Rental Profile",
            "Experian Credit Check and Address History",
            "Income and Savings Verification",
            "Identity Verification",
            "Canopy will chase applicants on your behalf",
            "Manual documents review when required",
          ],
        },
        comprehensive: {
          title: "Comprehensive",
          features: ["Everything in Essential", "Employment and Landlord References"],
        },
        actionButtonText: "Create Account",
        actionSubText: "Already have an account?",
        actionSubTextLink: "Sign in",
      },
    },
    properties: {
      manageProperty: {
        addTitle: "Create Property",
        editTitle: "Edit Property",
        addressSection: {
          address: "Address",
          addressDetails: "Address Details",
          addressLookup: "Address Lookup",
          flatNumber: "Flat Number",
          houseNumber: "House Number",
          houseName: "House Name",
          street: "Street",
          country: "Country",
          town: "Town/City",
          postCode: "Postcode",
          county: "County",
          selectCountry: "Select Country",
          typeHere: "Type here",
        },
        detailsSection: {
          details: "Details",
          internalCode: "Internal Code",
          branch: "Branch",
          selectBranch: "Select Branch",
          typeHere: "Type here",
        },
        targetRentSection: {
          targetRent: "Target Rent",
          rentFrequency: "Rent Frequency",
          selectFrequency: "Select Frequency",
          amount: "Amount",
          typeHere: "Type here",
          frequency: {
            MONTHLY: "Monthly",
            WEEKLY: "Weekly",
          },
        },
        addButtonText: "Property",
        editButtonText: "Edit",
        addSubmitText: "Create Property",
        editSubmitText: "Edit Property",
      },
      list: {
        title: "Properties",
        columnLabels: {
          address: "Address",
          branch: "Branch",
          targetRent: "Target Rent",
          internalCode: "Internal Code",
          dateCreated: "Date Created",
        },
        fallbackContent: {
          title: "You currently don't have any Properties",
          subTitle: "Add a Properties to manage renters as a group",
        },
      },
      details: {
        backBtnText: "Back to Results",
        detailsSection: {
          copy: "Copy to clipboard",
          labelProperty: "Property",
          labelTargetRent: "Target Rent",
          editBtnText: "Edit",
        },
        tenancySection: {
          btnText: "Add Tenancy",
          title: "Tenancies",
          rentAffordability: "Joint Affordability",
          edit: "Edit",
          delete: "Delete",
          noListText: "You currently don't have any tenancies attached to this property",
          deleteDialog: {
            title: "Delete Tenancy",
            description: "Are you sure to delete tenancy from this property?",
          },
          addDialog: {
            title: "Add Tenancy",
            tenancy: "Tenancy",
            placeholder: "Search by code or renter name",
            or: "Or",
            noRentersText: "No renters attached",
            create: "Create a new tenancy",
            btnText: "Add",
          },
        },
        renterSection: {
          btnText: "Add Renter",
          title: "Renters",
          noListText: "You currently don't have any renters attached to this property",
          delete: "Delete",
          screeningResult: "Screening Result",
          affordability: "Affordability",
          deleteDialog: {
            title: "Delete Renter",
            description: "Are you sure to delete renter from this property?",
          },
          addDialog: {
            title: "Add Renter",
            renter: "Renter",
            placeholder: "Search for existing renter here",
            or: "Or",
            invite: "Invite a renter",
            btnText: "Add",
            resultText: "Renters found",
          },
        },
      },
    },
    rightToRent: {
      tab: {
        all: "All",
        complete: "Complete",
        endingSoon: "Ending Soon",
      },
      list: {
        columnLabels: {
          name: "Name",
          status: "Right To Rent Status",
          email: "Email",
          branch: "Branch",
          endOfTenancy: "End Of Tenancy",
          expiryDate: "Expiry Date",
        },
        fallbackContent: {
          title: "You currently don't have any Right to Rents",
          subTitle: "Add a Right to Rents to manage renters as a group",
        },
        fallbackContentCompleted: {
          title: "You currently don't have any completed Right to Rents",
        },
        fallbackContentExpiry: {
          title: "You currently don't have any expired soon Right to Rents",
        },
      },
      filter: {
        labels: {
          status: "Right To Rent Status",
          endOfTenancy: "End of Tenancy Date",
          expiryDate: "Date Expires",
        },
      },
    },
    deposits: {
      DFI: "Deposit Free Insurance",
      standardDeposit: "Standard Cash Deposit",
      traditionalDeposit: "Traditional Cash Deposit",
      depositAgreement: "Deposit Agreement",
      addDepositAgreement: "Add Deposit Agreement",
      createDepositAgreement: "Create Deposit Agreement",
      sendDeposit: "Send Deposit Invites",
      makeClaim: "Make a claim",
      confirmationContent:
        "If you leave this page you will lose all progress on this Deposit Agreement.",
      depositOptions: {
        title: "Deposit Agreement Options",
        cost: "Deposit Cost",
        coverLength: "Cover length",
        coverAmount: "Cover amount",
        subBtnText:
          "This will send invitations to the renters to pay for their deposit agreements.",
        weeks: "weeks",
      },
      notificationBox: {
        title: "You are currently in",
        subTitle: "Change branch to see the deposit agreement.",
      },
      list: {
        columnLabels: {
          address: "Address",
          landlord: "Landlord",
          renters: "Renters",
          depositType: "Type",
          deposit: "Deposit (£)",
          startDate: "Start Date",
          endDate: "End Date",
          depositStatus: "Deposit Status",
        },
        fallbackContent: {
          title: "You currently don't have any Deposit Agreements",
          subTitle: "Create one for your tenants so they can pay for their deposits",
          btnText: "Add Deposit Agreement",
        },
      },
      filter: {
        labels: {
          depositType: "Type",
          depositStatus: "Deposit Status",
        },
      },
      depositType: {
        title: "Deposit type",
        subTitle:
          "Please be aware if the tenant does not have a credit score of 561 then they would not be eligible for Deposit Free Insurance.",
        cashDeposit: "Traditional Cash Deposit",
        inOrder: "In order to accept a Cash Deposit, you need to have an account with ",
        myDeposits: "MyDeposits.",
        myDepositsRefId: "MyDeposits Reference ID",
        freeInsurance: "Deposit Free Insurance",
        typeHere: "Type Here",
        DFIText:
          "Deposit Free is an affordable alternative to a cash deposit (usually around 10% of the cost). This simple insurance policy reduces moving costs for tenants, and protects both of you should the worst happen.",
        learnMore: "Learn more",
      },
      tenancyAddress: {
        title: "Tenancy address",
      },
      tenancyInfo: {
        title: "Tenancy information",
        tenancyLength: "Tenancy length",
        address: "Address",
        rent: "Rent",
        startDate: "Start date",
        endDate: "End date",
        rentFrequency: "Rent Frequency",
        amount: "Amount",
        monthlyRentAmount: "Monthly rent",
        rentAmount: "Rent Amount",
        frequency: {
          Weekly: "Weekly",
          Monthly: "Monthly",
        },
      },
      rentersInfo: {
        title: "Renters",
        subTitle:
          "Each tenant has an individual deposit agreement, whether it is Deposit Free Insurance or a Cash Deposit.",
        action: "Add renter",
        searchRenter: "Search for a renter that you are connected to",
        searchPlaceholder: "Enter Renter's email",
        screeningResult: "Screening Result",
        paymentStatus: "Payment Status",
        affordability: "Affordability",
        paymentType: {
          CASH_DEPOSIT: "Cash Deposit",
          DEPOSIT_FREE_INSURANCE: "Deposit Free",
        },
        eligible: "Eligible ",
        notEligible: "Not Eligible",
        rentShare: "Rent Share",
        pcm: "pcm",
        pcw: "pcw",
        generating: "generating",
        rentersDoesNotQualifyForDFI:
          "One or more of the selected renters does not qualify for DFI, either their Rent Passport is incomplete or they are not eligible. Please review or choose ‘Cash Deposit’ to proceed.",
        rentPassportIncomplete:
          "You cannot complete this deposit agreement until all renters have completed their Rent Passports",
        totalRentShareNotEqualRentAmount:
          "The rent share does not total the monthly rent. Please amend as you see fit.",
        rentShareMoreAffordability:
          "One or more of the tenants rent share is more than their affordability, consider lowering it.",
        fallBackCard: {
          title: "You currently don't have any renters attached",
          subTitle:
            "Please note, to be able to complete the deposit agreement, all renters will need to have completed their Rent Passports.",
        },
      },
      propManagement: {
        title: "Property management",
        propertyManager: "Manages the property",
        agreementOwner: "Deposit agreement owner",
        agent: "Agent",
        landlord: "Landlord",
        landlordNameLabel: "Landlord name",
        landlordNamePlaceholder: "Type landlord name here",
        landlordEmailLabel: "Landlord email",
        landlordEmailPlaceholder: "Type landlord email here",
        landlordPhoneLabel: "Landlord phone number",
        landlordPhonePlaceholder: "Type landlord phone number here",
      },
    },
    partnerServices: {
      title: "Partner Services",
      marketplaceIframeTitle: "Partner Services",
    },
    insurance: {
      title: "Insurance",
      iframeTitle: "Insurance",
    },
  },
  table: {
    applied: "Applied",
    results: " Results",
    filters: "Filters",
    show: "Show",
    hide: "Hide",
    searchInResults: "Search in results",
    filterLabel: "Filter",
    noDataFallback: {
      title: "Sorry, no results found",
      subTitle: "Please try another search",
      withActiveBranch: {
        subTitle: "Please try another search or",
        link: "search inside all branches",
      },
    },
    filter: {
      filterResults: "Filter Results",
      applyFilters: "Apply Filters",
    },
  },
  guarantor: {
    requested: "Guarantor Requested",
    invited: "Guarantor Invite Sent",
    declined: "Guarantor Invite Declined",
    requestSent: "Request sent",
    inviteSent: "Invite Sent",
    lastUpdated: "Last Updated",
    affordability: "Affordability",
    pcm: "pcm",
  },
  dialogs: {
    addressLookup: {
      existingAddressesTitle: "Existing addresses",
      otherSuggestionsTitle: "Addresses found",
      noDataTitle: "No Results",
      noDataText:
        "Type you full UK Postcode. If you are having trouble locating you address try adding a space",
    },
    postcodeLookup: {
      suggestionsTitle: "Addresses found",
      noDataTitle: "No Results",
      noDataText:
        "Type you full UK Postcode. If you are having trouble locating you address try adding a space",
    },
  },
};
